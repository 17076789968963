export const tokenList = () => [
  {
    name: "Wrapped Ether",
    address: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    symbol: "WETH",
    decimals: 18,
    chainId: 1,
    logoURI: "/img/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2.png",
    extensions: {
      bridgeInfo: {
        10: {
          tokenAddress: "0x4200000000000000000000000000000000000006",
        },
        137: {
          tokenAddress: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
        },
        42161: {
          tokenAddress: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
        },
        42220: {
          tokenAddress: "0x2DEf4285787d58a2f811AF24755A8150622f4361",
        },
      },
    },
  },
  {
    name: "Dai Stablecoin",
    address: "0x6b175474e89094c44da98b954eedeac495271d0f",
    symbol: "DAI",
    decimals: 18,
    chainId: 1,
    logoURI: "/img/0x6b175474e89094c44da98b954eedeac495271d0f.png",
  },
  {
    name: "0x Protocol Token",
    address: "0xe41d2489571d322189246dafa5ebde1f4699f498",
    symbol: "ZRX",
    decimals: 18,
    chainId: 1,
    logoURI: "/img/0xe41d2489571d322189246dafa5ebde1f4699f498.png",
  },
  {
    name: "Curve DAO Token",
    address: "0xd533a949740bb3306d119cc777fa900ba034cd52",
    symbol: "CRV",
    decimals: 18,
    chainId: 1,
    logoURI: "/img/0xd533a949740bb3306d119cc777fa900ba034cd52.png",
  },
  {
    name: "Uniswap",
    address: "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
    symbol: "UNI",
    decimals: 18,
    chainId: 1,
    logoURI: "/img/transaction.svg",
  },
  {
    name: "Tether USD",
    address: "0xdac17f958d2ee523a2206206994597c13d831ec7",
    symbol: "USDT",
    decimals: 6,
    chainId: 1,
    logoURI: "/img/0xdac17f958d2ee523a2206206994597c13d831ec7.png",
  },
  {
    name: "USDCoin",
    address: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    symbol: "USDC",
    decimals: 6,
    chainId: 1,
    logoURI: "/img/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png",
    extensions: {
      bridgeInfo: {
        42161: {
          tokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
        },
      },
    },
  },
  {
    name: "Orchid",
    address: "0x4575f41308ec1483f3d399aa9a2826d74da13deb",
    symbol: "OXT",
    decimals: 18,
    chainId: 1,
    logoURI: "/img/0x4575f41308ec1483f3d399aa9a2826d74da13deb.png",
  },
  {
    name: "Maker",
    address: "0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2",
    symbol: "MKR",
    decimals: 18,
    chainId: 1,
    logoURI: "/img/0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2.png",
  },
  {
    name: "ChainLink Token",
    address: "0x514910771af9ca656af840dff83e8264ecf986ca",
    symbol: "LINK",
    decimals: 18,
    chainId: 1,
    logoURI: "/img/0x514910771af9ca656af840dff83e8264ecf986ca.png",
  },
  {
    name: "Reputation Augur v1",
    address: "0x1985365e9f78359a9b6ad760e32412f4a445e862",
    symbol: "REP",
    decimals: 18,
    chainId: 1,
    logoURI: "/img/0x1985365e9f78359a9b6ad760e32412f4a445e862.png",
  },
  {
    name: "Reputation Augur v2",
    address: "0x221657776846890989a759ba2973e427dff5c9bb",
    symbol: "REPv2",
    decimals: 18,
    chainId: 1,
    logoURI: "/img/0x221657776846890989a759ba2973e427dff5c9bb.png",
  },
  {
    name: "Kyber Network Crystal",
    address: "0xdd974d5c2e2928dea5f71b9825b8b646686bd200",
    symbol: "KNC",
    decimals: 18,
    chainId: 1,
    logoURI: "/img/0xdd974d5c2e2928dea5f71b9825b8b646686bd200.png",
  },
  {
    name: "Compound",
    address: "0xc00e94cb662c3520282e6f5717214004a7f26888",
    symbol: "COMP",
    decimals: 18,
    chainId: 1,
    logoURI: "/img/0xc00e94cb662c3520282e6f5717214004a7f26888.png",
  },
  {
    chainId: 1,
    address: "0xba11d00c5f74255f56a5e366f4f77f5a186d7f55",
    name: "Band Protocol",
    symbol: "BAND",
    decimals: 18,
    logoURI: "/img/0xba11d00c5f74255f56a5e366f4f77f5a186d7f55.png",
  },
  {
    name: "Numeraire",
    address: "0x1776e1f26f98b1a5df9cd347953a26dd3cb46671",
    symbol: "NMR",
    decimals: 18,
    chainId: 1,
    logoURI: "/img/0x1776e1f26f98b1a5df9cd347953a26dd3cb46671.png",
  },
  {
    name: "UMA Voting Token v1",
    address: "0x04fa0d235c4abf4bcf4787af4cf447de572ef828",
    symbol: "UMA",
    decimals: 18,
    chainId: 1,
    logoURI: "/img/0x04fa0d235c4abf4bcf4787af4cf447de572ef828.png",
  },
  {
    name: "LoopringCoin V2",
    address: "0xbbbbca6a901c926f240b89eacb641d8aec7aeafd",
    symbol: "LRC",
    decimals: 18,
    chainId: 1,
    logoURI: "/img/0xbbbbca6a901c926f240b89eacb641d8aec7aeafd.png",
  },
  {
    chainId: 1,
    address: "0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e",
    name: "yearn finance",
    symbol: "YFI",
    decimals: 18,
    logoURI: "/img/0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e.png",
  },
  {
    name: "Republic Token",
    address: "0x408e41876cccdc0f92210600ef50372656052a38",
    symbol: "REN",
    decimals: 18,
    chainId: 1,
    logoURI: "/img/0x408e41876cccdc0f92210600ef50372656052a38.png",
  },
  {
    name: "Wrapped BTC",
    address: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
    symbol: "WBTC",
    decimals: 8,
    chainId: 1,
    logoURI: "/img/0x2260fac5e5542a773aa44fbcfedf7c193bc2c599.png",
  },
  {
    name: "Balancer",
    address: "0xba100000625a3754423978a60c9317c58a424e3d",
    symbol: "BAL",
    decimals: 18,
    chainId: 1,
    logoURI: "/img/0xba100000625a3754423978a60c9317c58a424e3d.png",
  },
  {
    chainId: 1,
    address: "0x4fe83213d56308330ec302a8bd641f1d0113a4cc",
    name: "NuCypher",
    symbol: "NU",
    decimals: 18,
    logoURI: "/img/0x4fe83213d56308330ec302a8bd641f1d0113a4cc.jpg",
  },
  {
    chainId: 1,
    address: "0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9",
    name: "Aave",
    symbol: "AAVE",
    decimals: 18,
    logoURI: "/img/0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9.png",
    extensions: {
      bridgeInfo: {
        10: {
          tokenAddress: "0x76FB31fb4af56892A25e32cFC43De717950c9278",
        },
      },
    },
  },
  {
    chainId: 1,
    address: "0xc944e90c64b2c07662a292be6244bdf05cda44a7",
    name: "The Graph",
    symbol: "GRT",
    decimals: 18,
    logoURI: "/img/0xc944e90c64b2c07662a292be6244bdf05cda44a7.png",
  },
  {
    name: "Bancor Network Token",
    address: "0x1f573d6fb3f13d689ff844b4ce37794d79a7ff1c",
    symbol: "BNT",
    decimals: 18,
    chainId: 1,
    logoURI: "/img/0x1f573d6fb3f13d689ff844b4ce37794d79a7ff1c.png",
  },
  {
    name: "Synthetix Network Token",
    address: "0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f",
    symbol: "SNX",
    decimals: 18,
    chainId: 1,
    logoURI: "/img/0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f.png",
  },
  {
    chainId: 1,
    address: "0x0f5d2fb29fb7d3cfee444a200298f468908cc942",
    name: "Decentraland",
    symbol: "MANA",
    decimals: 18,
    logoURI: "/img/0x0f5d2fb29fb7d3cfee444a200298f468908cc942.png",
  },
  {
    name: "Loom Network",
    address: "0xa4e8c3ec456107ea67d3075bf9e3df3a75823db0",
    symbol: "LOOM",
    decimals: 18,
    chainId: 1,
    logoURI: "/img/0xa4e8c3ec456107ea67d3075bf9e3df3a75823db0.png",
  },
  {
    chainId: 1,
    address: "0x41e5560054824ea6b0732e656e3ad64e20e94e45",
    name: "Civic",
    symbol: "CVC",
    decimals: 8,
    logoURI: "/img/0x41e5560054824ea6b0732e656e3ad64e20e94e45.png",
  },
  {
    chainId: 1,
    address: "0x0abdace70d3790235af448c88547603b945604ea",
    name: "district0x",
    symbol: "DNT",
    decimals: 18,
    logoURI: "/img/0x0abdace70d3790235af448c88547603b945604ea.png",
  },
  {
    name: "Storj Token",
    address: "0xb64ef51c888972c908cfacf59b47c1afbc0ab8ac",
    symbol: "STORJ",
    decimals: 8,
    chainId: 1,
    logoURI: "/img/0xb64ef51c888972c908cfacf59b47c1afbc0ab8ac.png",
  },
  {
    chainId: 1,
    address: "0xff20817765cb7f73d4bde2e66e067e58d11095c2",
    name: "Amp",
    symbol: "AMP",
    decimals: 18,
    logoURI: "/img/0xff20817765cb7f73d4bde2e66e067e58d11095c2.png",
  },
  {
    name: "Gnosis Token",
    address: "0x6810e776880c02933d47db1b9fc05908e5386b96",
    symbol: "GNO",
    decimals: 18,
    chainId: 1,
    logoURI: "/img/0x6810e776880c02933d47db1b9fc05908e5386b96.png",
  },
  {
    name: "Aragon",
    address: "0xa117000000f279d81a1d3cc75430faa017fa5a2e",
    symbol: "ANT",
    decimals: 18,
    chainId: 1,
    logoURI: "/img/0xa117000000f279d81a1d3cc75430faa017fa5a2e.png",
  },
  {
    chainId: 1,
    address: "0x85eee30c52b0b379b046fb0f85f4f3dc3009afec",
    name: "Keep Network",
    symbol: "KEEP",
    decimals: 18,
    logoURI: "/img/0x85eee30c52b0b379b046fb0f85f4f3dc3009afec.jpg",
  },
  {
    chainId: 1,
    address: "0x18084fba666a33d37592fa2633fd49a74dd93a88",
    name: "tBTC",
    symbol: "tBTC",
    decimals: 18,
    logoURI: "/img/0x18084fba666a33d37592fa2633fd49a74dd93a88.png",
  },
  {
    chainId: 1,
    address: "0xec67005c4e498ec7f55e092bd1d35cbc47c91892",
    name: "Melon",
    symbol: "MLN",
    decimals: 18,
    logoURI: "/img/0xec67005c4e498ec7f55e092bd1d35cbc47c91892.png",
  },
  {
    chainId: 1,
    address: "0xc18360217d8f7ab5e7c516566761ea12ce7f9d72",
    name: "Ethereum Name Service",
    symbol: "ENS",
    decimals: 18,
    logoURI: "/img/0xc18360217d8f7ab5e7c516566761ea12ce7f9d72.jpg",
  },
  {
    name: "Synth sUSD",
    address: "0x57ab1ec28d129707052df4df418d58a2d46d5f51",
    symbol: "sUSD",
    decimals: 18,
    chainId: 1,
    logoURI: "/img/0x57ab1ec28d129707052df4df418d58a2d46d5f51.png",
  },
  {
    chainId: 1,
    address: "0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0",
    name: "Polygon",
    symbol: "MATIC",
    decimals: 18,
    logoURI: "/img/0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0.png",
    extensions: {
      bridgeInfo: {
        137: {
          tokenAddress: "0x0000000000000000000000000000000000001010",
        },
      },
    },
  },
  {
    chainId: 1,
    address: "0x4d224452801aced8b2f0aebe155379bb5d594381",
    name: "ApeCoin",
    symbol: "APE",
    decimals: 18,
    logoURI: "/img/0x4d224452801aced8b2f0aebe155379bb5d594381.jpg",
  },
  {
    chainId: 1,
    address: "0x111111111117dc0aa78b770fa6a738034120c302",
    name: "1inch",
    symbol: "1INCH",
    decimals: 18,
    logoURI: "/img/0x111111111117dc0aa78b770fa6a738034120c302.png",
  },
  {
    chainId: 1,
    address: "0x91af0fbb28aba7e31403cb457106ce79397fd4e6",
    name: "Aergo",
    symbol: "AERGO",
    decimals: 18,
    logoURI: "/img/0x91af0fbb28aba7e31403cb457106ce79397fd4e6.png",
  },
  {
    chainId: 1,
    address: "0x626e8036deb333b408be468f951bdb42433cbf18",
    name: "AIOZ Network",
    symbol: "AIOZ",
    decimals: 18,
    logoURI: "/img/0x626e8036deb333b408be468f951bdb42433cbf18.png",
  },
  {
    chainId: 1,
    address: "0xdbdb4d16eda451d0503b854cf79d55697f90c8df",
    name: "Alchemix",
    symbol: "ALCX",
    decimals: 18,
    logoURI: "/img/0xdbdb4d16eda451d0503b854cf79d55697f90c8df.png",
  },
  {
    chainId: 1,
    address: "0x845576c64f9754cf09d87e45b720e82f3eef522c",
    name: "Artverse Token",
    symbol: "AVT",
    decimals: 18,
    logoURI: "/img/0x845576c64f9754cf09d87e45b720e82f3eef522c.png",
  },
  {
    chainId: 1,
    address: "0x32353a6c91143bfd6c7d363b546e62a9a2489a20",
    name: "Adventure Gold",
    symbol: "AGLD",
    decimals: 18,
    logoURI: "/img/0x32353a6c91143bfd6c7d363b546e62a9a2489a20.jpg",
  },
  {
    chainId: 1,
    address: "0x0391d2021f89dc339f60fff84546ea23e337750f",
    name: "BarnBridge",
    symbol: "BOND",
    decimals: 18,
    logoURI: "/img/0x0391d2021f89dc339f60fff84546ea23e337750f.jpg",
  },
  {
    chainId: 1,
    address: "0x77fba179c79de5b7653f68b5039af940ada60ce0",
    name: "Ampleforth Governance Token",
    symbol: "FORTH",
    decimals: 18,
    logoURI: "/img/0x77fba179c79de5b7653f68b5039af940ada60ce0.jpg",
  },
  {
    chainId: 1,
    address: "0x8290333cef9e6d528dd5618fb97a76f268f3edd4",
    name: "Ankr",
    symbol: "ANKR",
    decimals: 18,
    logoURI: "/img/0x8290333cef9e6d528dd5618fb97a76f268f3edd4.png",
  },
  {
    chainId: 1,
    address: "0x0b38210ea11411557c13457d4da7dc6ea731b88a",
    name: "API3",
    symbol: "API3",
    decimals: 18,
    logoURI: "/img/0x0b38210ea11411557c13457d4da7dc6ea731b88a.jpg",
  },
  {
    chainId: 1,
    address: "0xba50933c268f567bdc86e1ac131be072c6b0b71a",
    name: "ARPA Chain",
    symbol: "ARPA",
    decimals: 18,
    logoURI: "/img/0xba50933c268f567bdc86e1ac131be072c6b0b71a.jpg",
  },
  {
    chainId: 1,
    address: "0x2565ae0385659badcada1031db704442e1b69982",
    name: "Assemble Protocol",
    symbol: "ASM",
    decimals: 18,
    logoURI: "/img/0x2565ae0385659badcada1031db704442e1b69982.jpg",
  },
  {
    chainId: 1,
    address: "0x80c62fe4487e1351b47ba49809ebd60ed085bf52",
    name: "Clover Finance",
    symbol: "CLV",
    decimals: 18,
    logoURI: "/img/0x80c62fe4487e1351b47ba49809ebd60ed085bf52.png",
  },
  {
    chainId: 1,
    address: "0x321c2fe4446c7c963dc41dd58879af648838f98d",
    name: "Cryptex Finance",
    symbol: "CTX",
    decimals: 18,
    logoURI: "/img/0x321c2fe4446c7c963dc41dd58879af648838f98d.png",
  },
  {
    chainId: 1,
    address: "0xbb0e17ef65f82ab018d8edd776e8dd940327b28b",
    name: "Axie Infinity",
    symbol: "AXS",
    decimals: 18,
    logoURI: "/img/0xbb0e17ef65f82ab018d8edd776e8dd940327b28b.png",
  },
  {
    chainId: 1,
    address: "0x3472a5a71965499acd81997a54bba8d852c6e53d",
    name: "Badger DAO",
    symbol: "BADGER",
    decimals: 18,
    logoURI: "/img/0x3472a5a71965499acd81997a54bba8d852c6e53d.jpg",
  },
  {
    chainId: 1,
    address: "0xa0b73e1ff0b80914ab6fe0444e65848c4c34450b",
    name: "Cronos",
    symbol: "CRO",
    decimals: 8,
    logoURI: "/img/0xa0b73e1ff0b80914ab6fe0444e65848c4c34450b.jpg",
  },
  {
    chainId: 1,
    address: "0x0d8775f648430679a709e98d2b0cb6250d2887ef",
    name: "Basic Attention Token",
    symbol: "BAT",
    decimals: 18,
    logoURI: "/img/0x0d8775f648430679a709e98d2b0cb6250d2887ef.png",
  },
  {
    chainId: 1,
    address: "0xf17e65822b568b3903685a7c9f496cf7656cc6c2",
    name: "Biconomy",
    symbol: "BICO",
    decimals: 18,
    logoURI: "/img/0xf17e65822b568b3903685a7c9f496cf7656cc6c2.jpg",
  },
  {
    chainId: 1,
    address: "0xde30da39c46104798bb5aa3fe8b9e0e1f348163f",
    name: "Gitcoin",
    symbol: "GTC",
    decimals: 18,
    logoURI: "/img/0xde30da39c46104798bb5aa3fe8b9e0e1f348163f.png",
  },
  {
    chainId: 1,
    address: "0xf57e7e7c23978c3caec3c3548e3d615c346e79ff",
    name: "Immutable X",
    symbol: "IMX",
    decimals: 18,
    logoURI: "/img/0xf57e7e7c23978c3caec3c3548e3d615c346e79ff.png",
  },
  {
    chainId: 1,
    address: "0x5732046a883704404f284ce41ffadd5b007fd668",
    name: "Bluzelle",
    symbol: "BLZ",
    decimals: 18,
    logoURI: "/img/0x5732046a883704404f284ce41ffadd5b007fd668.png",
  },
  {
    chainId: 1,
    address: "0x69af81e73a73b40adf4f3d4223cd9b1ece623074",
    name: "Mask Network",
    symbol: "MASK",
    decimals: 18,
    logoURI: "/img/0x69af81e73a73b40adf4f3d4223cd9b1ece623074.jpg",
  },
  {
    chainId: 1,
    address: "0xa9b1eb5908cfc3cdf91f9b8b3a74108598009096",
    name: "Bounce",
    symbol: "AUCTION",
    decimals: 18,
    logoURI: "/img/0xa9b1eb5908cfc3cdf91f9b8b3a74108598009096.jpg",
  },
  {
    chainId: 1,
    address: "0x799ebfabe77a6e34311eeee9825190b9ece32824",
    name: "Braintrust",
    symbol: "BTRST",
    decimals: 18,
    logoURI: "/img/0x799ebfabe77a6e34311eeee9825190b9ece32824.png",
  },
  {
    chainId: 1,
    address: "0x3a4f40631a4f906c2bad353ed06de7a5d3fcb430",
    name: "PlayDapp",
    symbol: "PLA",
    decimals: 18,
    logoURI: "/img/0x3a4f40631a4f906c2bad353ed06de7a5d3fcb430.png",
  },
  {
    chainId: 1,
    address: "0x491604c0fdf08347dd1fa4ee062a822a5dd06b5d",
    name: "Cartesi",
    symbol: "CTSI",
    decimals: 18,
    logoURI: "/img/0x491604c0fdf08347dd1fa4ee062a822a5dd06b5d.png",
  },
  {
    chainId: 1,
    address: "0xd8912c10681d8b21fd3742244f44658dba12264e",
    name: "Pluton",
    symbol: "PLU",
    decimals: 18,
    logoURI: "/img/0xd8912c10681d8b21fd3742244f44658dba12264e.png",
  },
  {
    chainId: 1,
    address: "0x3506424f91fd33084466f402d5d97f05f8e3b4af",
    name: "Chiliz",
    symbol: "CHZ",
    decimals: 18,
    logoURI: "/img/0x3506424f91fd33084466f402d5d97f05f8e3b4af.png",
  },
  {
    chainId: 1,
    address: "0x9e46a38f5daabe8683e10793b06749eef7d733d1",
    name: "PolySwarm",
    symbol: "NCT",
    decimals: 18,
    logoURI: "/img/0x9e46a38f5daabe8683e10793b06749eef7d733d1.jpg",
  },
  {
    chainId: 1,
    address: "0x03ab458634910aad20ef5f1c8ee96f1d6ac54919",
    name: "Rai Reflex Index",
    symbol: "RAI",
    decimals: 18,
    logoURI: "/img/0x03ab458634910aad20ef5f1c8ee96f1d6ac54919.png",
  },
  {
    chainId: 1,
    address: "0xddb3422497e61e13543bea06989c0789117555c5",
    name: "COTI",
    symbol: "COTI",
    decimals: 18,
    logoURI: "/img/0xddb3422497e61e13543bea06989c0789117555c5.png",
  },
  {
    chainId: 1,
    address: "0xd31a59c85ae9d8edefec411d448f90841571b89c",
    name: "SOL Wormhole ",
    symbol: "SOL",
    decimals: 9,
    logoURI: "/img/0xd31a59c85ae9d8edefec411d448f90841571b89c.png",
  },
  {
    chainId: 1,
    address: "0x08389495d7456e1951ddf7c3a1314a4bfb646d8b",
    name: "Crypterium",
    symbol: "CRPT",
    decimals: 18,
    logoURI: "/img/0x08389495d7456e1951ddf7c3a1314a4bfb646d8b.png",
  },
  {
    chainId: 1,
    address: "0xe53ec727dbdeb9e2d5456c3be40cff031ab40a55",
    name: "SuperFarm",
    symbol: "SUPER",
    decimals: 18,
    logoURI: "/img/0xe53ec727dbdeb9e2d5456c3be40cff031ab40a55.png",
  },
  {
    chainId: 1,
    address: "0x0f2d719407fdbeff09d87557abb7232601fd9f29",
    name: "Synapse",
    symbol: "SYN",
    decimals: 18,
    logoURI: "/img/0x0f2d719407fdbeff09d87557abb7232601fd9f29.png",
  },
  {
    chainId: 1,
    address: "0x4c19596f5aaff459fa38b0f7ed92f11ae6543784",
    name: "TrueFi",
    symbol: "TRU",
    decimals: 8,
    logoURI: "/img/0x4c19596f5aaff459fa38b0f7ed92f11ae6543784.png",
  },
  {
    chainId: 1,
    address: "0x3a880652f47bfaa771908c07dd8673a787daed3a",
    name: "DerivaDAO",
    symbol: "DDX",
    decimals: 18,
    logoURI: "/img/0x3a880652f47bfaa771908c07dd8673a787daed3a.png",
  },
  {
    chainId: 1,
    address: "0xa1d0e215a23d7030842fc67ce582a6afa3ccab83",
    name: "DFI money",
    symbol: "YFII",
    decimals: 18,
    logoURI: "/img/0xa1d0e215a23d7030842fc67ce582a6afa3ccab83.png",
  },
  {
    chainId: 1,
    address: "0x84ca8bc7997272c7cfb4d0cd3d55cd942b3c9419",
    name: "DIA",
    symbol: "DIA",
    decimals: 18,
    logoURI: "/img/0x84ca8bc7997272c7cfb4d0cd3d55cd942b3c9419.png",
  },
  {
    chainId: 1,
    address: "0xa4eed63db85311e22df4473f87ccfc3dadcfa3e3",
    name: "Rubic",
    symbol: "RBC",
    decimals: 18,
    logoURI: "/img/0xa4eed63db85311e22df4473f87ccfc3dadcfa3e3.png",
  },
  {
    chainId: 1,
    address: "0xf629cbd94d3791c9250152bd8dfbdf380e2a3b9c",
    name: "Enjin Coin",
    symbol: "ENJ",
    decimals: 18,
    logoURI: "/img/0xf629cbd94d3791c9250152bd8dfbdf380e2a3b9c.png",
  },
  {
    chainId: 1,
    address: "0xba5bde662c17e2adff1075610382b9b691296350",
    name: "SuperRare",
    symbol: "RARE",
    decimals: 18,
    logoURI: "/img/0xba5bde662c17e2adff1075610382b9b691296350.jpg",
  },
  {
    chainId: 1,
    address: "0x2e9d63788249371f1dfc918a52f8d799f4a38c94",
    name: "Tokemak",
    symbol: "TOKE",
    decimals: 18,
    logoURI: "/img/0x2e9d63788249371f1dfc918a52f8d799f4a38c94.png",
  },
  {
    chainId: 1,
    address: "0xbbc2ae13b23d715c30720f079fcd9b4a74093505",
    name: "Ethernity Chain",
    symbol: "ERN",
    decimals: 18,
    logoURI: "/img/0xbbc2ae13b23d715c30720f079fcd9b4a74093505.png",
  },
  {
    chainId: 1,
    address: "0xaea46a60368a7bd060eec7df8cba43b7ef41ad85",
    name: "Fetch ai",
    symbol: "FET",
    decimals: 18,
    logoURI: "/img/0xaea46a60368a7bd060eec7df8cba43b7ef41ad85.jpg",
  },
  {
    chainId: 1,
    address: "0x8c15ef5b4b21951d50e53e4fbda8298ffad25057",
    name: "Function X",
    symbol: "FX",
    decimals: 18,
    logoURI: "/img/0x8c15ef5b4b21951d50e53e4fbda8298ffad25057.jpg",
  },
  {
    chainId: 1,
    address: "0xccc8cb5229b0ac8069c51fd58367fd1e622afd97",
    name: "Gods Unchained",
    symbol: "GODS",
    decimals: 18,
    logoURI: "/img/0xccc8cb5229b0ac8069c51fd58367fd1e622afd97.png",
  },
  {
    chainId: 1,
    address: "0xdab396ccf3d84cf2d07c4454e10c8a6f5b008d2b",
    name: "Goldfinch",
    symbol: "GFI",
    decimals: 18,
    logoURI: "/img/0xdab396ccf3d84cf2d07c4454e10c8a6f5b008d2b.png",
  },
  {
    chainId: 1,
    address: "0x7dd9c5cba05e151c895fde1cf355c9a1d5da6429",
    name: "Golem",
    symbol: "GLM",
    decimals: 18,
    logoURI: "/img/0x7dd9c5cba05e151c895fde1cf355c9a1d5da6429.png",
  },
  {
    chainId: 1,
    address: "0xc08512927d12348f6620a698105e1baac6ecd911",
    name: "GYEN",
    symbol: "GYEN",
    decimals: 6,
    logoURI: "/img/0xc08512927d12348f6620a698105e1baac6ecd911.png",
  },
  {
    chainId: 1,
    address: "0xa0246c9032bc3a600820415ae600c6388619a14d",
    name: "Harvest Finance",
    symbol: "FARM",
    decimals: 18,
    logoURI: "/img/0xa0246c9032bc3a600820415ae600c6388619a14d.png",
  },
  {
    chainId: 1,
    address: "0x71ab77b7dbb4fa7e017bc15090b2163221420282",
    name: "Highstreet",
    symbol: "HIGH",
    decimals: 18,
    logoURI: "/img/0x71ab77b7dbb4fa7e017bc15090b2163221420282.png",
  },
  {
    chainId: 1,
    address: "0xb705268213d593b8fd88d3fdeff93aff5cbdcfae",
    name: "IDEX",
    symbol: "IDEX",
    decimals: 18,
    logoURI: "/img/0xb705268213d593b8fd88d3fdeff93aff5cbdcfae.png",
  },
  {
    chainId: 1,
    address: "0x607f4c5bb672230e8672085532f7e901544a7375",
    name: "iExec RLC",
    symbol: "RLC",
    decimals: 9,
    logoURI: "/img/0x607f4c5bb672230e8672085532f7e901544a7375.png",
  },
  {
    chainId: 1,
    address: "0x41d5d79431a913c4ae7d69a668ecdfe5ff9dfb68",
    name: "Inverse Finance",
    symbol: "INV",
    decimals: 18,
    logoURI: "/img/0x41d5d79431a913c4ae7d69a668ecdfe5ff9dfb68.jpg",
  },
  {
    chainId: 1,
    address: "0x7420b4b9a0110cdc71fb720908340c03f9bc03ec",
    name: "JasmyCoin",
    symbol: "JASMY",
    decimals: 18,
    logoURI: "/img/0x7420b4b9a0110cdc71fb720908340c03f9bc03ec.jpg",
  },
  {
    chainId: 1,
    address: "0x464ebe77c293e473b48cfe96ddcf88fcf7bfdac0",
    name: "KRYLL",
    symbol: "KRL",
    decimals: 18,
    logoURI: "/img/0x464ebe77c293e473b48cfe96ddcf88fcf7bfdac0.png",
  },
  {
    chainId: 1,
    address: "0x037a54aab062628c9bbae1fdb1583c195585fe41",
    name: "LCX",
    symbol: "LCX",
    decimals: 18,
    logoURI: "/img/0x037a54aab062628c9bbae1fdb1583c195585fe41.jpg",
  },
  {
    chainId: 1,
    address: "0x6dea81c8171d0ba574754ef6f8b412f2ed88c54d",
    name: "Liquity",
    symbol: "LQTY",
    decimals: 18,
    logoURI: "/img/0x6dea81c8171d0ba574754ef6f8b412f2ed88c54d.png",
  },
  {
    chainId: 1,
    address: "0x58b6a8a3302369daec383334672404ee733ab239",
    name: "Livepeer",
    symbol: "LPT",
    decimals: 18,
    logoURI: "/img/0x58b6a8a3302369daec383334672404ee733ab239.png",
  },
  {
    chainId: 1,
    address: "0x33349b282065b0284d756f0577fb39c158f935e6",
    name: "Maple",
    symbol: "MPL",
    decimals: 18,
    logoURI: "/img/0x33349b282065b0284d756f0577fb39c158f935e6.jpg",
  },
  {
    chainId: 1,
    address: "0x814e0908b12a99fecf5bc101bb5d0b8b5cdf7d26",
    name: "Measurable Data Token",
    symbol: "MDT",
    decimals: 18,
    logoURI: "/img/0x814e0908b12a99fecf5bc101bb5d0b8b5cdf7d26.png",
  },
  {
    chainId: 1,
    address: "0x09a3ecafa817268f77be1283176b946c4ff2e608",
    name: "Mirror Protocol",
    symbol: "MIR",
    decimals: 18,
    logoURI: "/img/0x09a3ecafa817268f77be1283176b946c4ff2e608.png",
  },
  {
    chainId: 1,
    address: "0xfc98e825a2264d890f9a1e68ed50e1526abccacd",
    name: "Moss Carbon Credit",
    symbol: "MCO2",
    decimals: 18,
    logoURI: "/img/0xfc98e825a2264d890f9a1e68ed50e1526abccacd.jpg",
  },
  {
    chainId: 1,
    address: "0xe2f2a5c287993345a840db3b0845fbc70f5935a5",
    name: "mStable USD",
    symbol: "MUSD",
    decimals: 18,
    logoURI: "/img/0xe2f2a5c287993345a840db3b0845fbc70f5935a5.png",
  },
  {
    chainId: 1,
    address: "0xd26114cd6ee289accf82350c8d8487fedb8a0c07",
    name: "OMG Network",
    symbol: "OMG",
    decimals: 18,
    logoURI: "/img/0xd26114cd6ee289accf82350c8d8487fedb8a0c07.jpg",
  },
  {
    chainId: 1,
    address: "0x8207c1ffc5b6804f6024322ccf34f29c3541ae26",
    name: "Origin Protocol",
    symbol: "OGN",
    decimals: 18,
    logoURI: "/img/0x8207c1ffc5b6804f6024322ccf34f29c3541ae26.jpg",
  },
  {
    chainId: 1,
    address: "0xaa7a9ca87d3694b5755f213b5d04094b8d0f0a6f",
    name: "OriginTrail",
    symbol: "TRAC",
    decimals: 18,
    logoURI: "/img/0xaa7a9ca87d3694b5755f213b5d04094b8d0f0a6f.jpg",
  },
  {
    chainId: 1,
    address: "0x0258f474786ddfd37abce6df6bbb1dd5dfc4434a",
    name: "Orion Protocol",
    symbol: "ORN",
    decimals: 8,
    logoURI: "/img/0x0258f474786ddfd37abce6df6bbb1dd5dfc4434a.png",
  },
  {
    chainId: 1,
    address: "0x70d2b7c19352bb76e4409858ff5746e500f2b67c",
    name: "Pawtocol",
    symbol: "UPI",
    decimals: 18,
    logoURI: "/img/0x70d2b7c19352bb76e4409858ff5746e500f2b67c.jpg",
  },
  {
    chainId: 1,
    address: "0xbc396689893d065f41bc2c6ecbee5e0085233447",
    name: "Perpetual Protocol",
    symbol: "PERP",
    decimals: 18,
    logoURI: "/img/0xbc396689893d065f41bc2c6ecbee5e0085233447.png",
  },
  {
    chainId: 1,
    address: "0x6fb3e0a217407efff7ca062d46c26e5d60a14d69",
    name: "IoTeX",
    symbol: "IOTX",
    decimals: 18,
    logoURI: "/img/0x6fb3e0a217407efff7ca062d46c26e5d60a14d69.png",
  },
  {
    chainId: 1,
    address: "0x83e6f1e41cdd28eaceb20cb649155049fac3d5aa",
    name: "Polkastarter",
    symbol: "POLS",
    decimals: 18,
    logoURI: "/img/0x83e6f1e41cdd28eaceb20cb649155049fac3d5aa.png",
  },
  {
    chainId: 1,
    address: "0x9992ec3cf6a55b00978cddf2b27bc6882d88d1ec",
    name: "Polymath",
    symbol: "POLY",
    decimals: 18,
    logoURI: "/img/0x9992ec3cf6a55b00978cddf2b27bc6882d88d1ec.png",
  },
  {
    chainId: 1,
    address: "0x226bb599a12c826476e3a771454697ea52e9e220",
    name: "Propy",
    symbol: "PRO",
    decimals: 8,
    logoURI: "/img/0x226bb599a12c826476e3a771454697ea52e9e220.png",
  },
  {
    chainId: 1,
    address: "0x4a220e6096b25eadb88358cb44068a3248254675",
    name: "Quant",
    symbol: "QNT",
    decimals: 18,
    logoURI: "/img/0x4a220e6096b25eadb88358cb44068a3248254675.jpg",
  },
  {
    chainId: 1,
    address: "0x99ea4db9ee77acd40b119bd1dc4e33e1c070b80d",
    name: "Quantstamp",
    symbol: "QSP",
    decimals: 18,
    logoURI: "/img/0x99ea4db9ee77acd40b119bd1dc4e33e1c070b80d.png",
  },
  {
    chainId: 1,
    address: "0x6c28aef8977c9b773996d0e8376d2ee379446f2f",
    name: "Quickswap",
    symbol: "QUICK",
    decimals: 18,
    logoURI: "/img/0x6c28aef8977c9b773996d0e8376d2ee379446f2f.png",
  },
  {
    chainId: 1,
    address: "0x31c8eacbffdd875c74b94b077895bd78cf1e64a3",
    name: "Radicle",
    symbol: "RAD",
    decimals: 18,
    logoURI: "/img/0x31c8eacbffdd875c74b94b077895bd78cf1e64a3.png",
  },
  {
    chainId: 1,
    address: "0x5cf04716ba20127f1e2297addcf4b5035000c9eb",
    name: "NKN",
    symbol: "NKN",
    decimals: 18,
    logoURI: "/img/0x5cf04716ba20127f1e2297addcf4b5035000c9eb.png",
  },
  {
    chainId: 1,
    address: "0xf1f955016ecbcd7321c7266bccfb96c68ea5e49b",
    name: "Rally",
    symbol: "RLY",
    decimals: 18,
    logoURI: "/img/0xf1f955016ecbcd7321c7266bccfb96c68ea5e49b.png",
  },
  {
    chainId: 1,
    address: "0xd291e7a03283640fdc51b121ac401383a46cc623",
    name: "Rari Governance Token",
    symbol: "RGT",
    decimals: 18,
    logoURI: "/img/0xd291e7a03283640fdc51b121ac401383a46cc623.png",
  },
  {
    chainId: 1,
    address: "0xfca59cd816ab1ead66534d82bc21e7515ce441cf",
    name: "Rarible",
    symbol: "RARI",
    decimals: 18,
    logoURI: "/img/0xfca59cd816ab1ead66534d82bc21e7515ce441cf.png",
  },
  {
    chainId: 1,
    address: "0x6de037ef9ad2725eb40118bb1702ebb27e4aeb24",
    name: "Render Token",
    symbol: "RNDR",
    decimals: 18,
    logoURI: "/img/0x6de037ef9ad2725eb40118bb1702ebb27e4aeb24.png",
  },
  {
    chainId: 1,
    address: "0x8f8221afbb33998d8584a2b05749ba73c37a938a",
    name: "Request",
    symbol: "REQ",
    decimals: 18,
    logoURI: "/img/0x8f8221afbb33998d8584a2b05749ba73c37a938a.png",
  },
  {
    chainId: 1,
    address: "0x6123b0049f904d730db3c36a31167d9d4121fa6b",
    name: "Ribbon Finance",
    symbol: "RBN",
    decimals: 18,
    logoURI: "/img/0x6123b0049f904d730db3c36a31167d9d4121fa6b.png",
  },
  {
    chainId: 1,
    address: "0xc770eefad204b5180df6a14ee197d99d808ee52d",
    name: "ShapeShift FOX Token",
    symbol: "FOX",
    decimals: 18,
    logoURI: "/img/0xc770eefad204b5180df6a14ee197d99d808ee52d.png",
  },
  {
    chainId: 1,
    address: "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
    name: "Shiba Inu",
    symbol: "SHIB",
    decimals: 18,
    logoURI: "/img/0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce.png",
  },
  {
    chainId: 1,
    address: "0x7c84e62859d0715eb77d1b1c4154ecd6abb21bec",
    name: "Shping",
    symbol: "SHPING",
    decimals: 18,
    logoURI: "/img/0x7c84e62859d0715eb77d1b1c4154ecd6abb21bec.jpg",
  },
  {
    chainId: 1,
    address: "0x00c83aecc790e8a4453e5dd3b0b4b3680501a7a7",
    name: "SKALE",
    symbol: "SKL",
    decimals: 18,
    logoURI: "/img/0x00c83aecc790e8a4453e5dd3b0b4b3680501a7a7.png",
  },
  {
    chainId: 1,
    address: "0x6f59e0461ae5e2799f1fb3847f05a63b16d0dbf8",
    name: "ORCA Alliance",
    symbol: "ORCA",
    decimals: 18,
    logoURI: "/img/0x6f59e0461ae5e2799f1fb3847f05a63b16d0dbf8.png",
  },
  {
    chainId: 1,
    address: "0x090185f2135308bad17527004364ebcc2d37e5f6",
    name: "Spell Token",
    symbol: "SPELL",
    decimals: 18,
    logoURI: "/img/0x090185f2135308bad17527004364ebcc2d37e5f6.png",
  },
  {
    chainId: 1,
    address: "0x006bea43baa3f7a6f765f14f10a1a1b08334ef45",
    name: "Stox",
    symbol: "STX",
    decimals: 18,
    logoURI: "/img/0x006bea43baa3f7a6f765f14f10a1a1b08334ef45.png",
  },
  {
    chainId: 1,
    address: "0x744d70fdbe2ba4cf95131626614a1763df805b9e",
    name: "Status",
    symbol: "SNT",
    decimals: 18,
    logoURI: "/img/0x744d70fdbe2ba4cf95131626614a1763df805b9e.png",
  },
  {
    chainId: 1,
    address: "0xc1d204d77861def49b6e769347a883b15ec397ff",
    name: "PayperEx",
    symbol: "PAX",
    decimals: 18,
    logoURI: "/img/0xc1d204d77861def49b6e769347a883b15ec397ff.png",
  },
  {
    chainId: 1,
    address: "0x0763fdccf1ae541a5961815c0872a8c5bc6de4d7",
    name: "SUKU",
    symbol: "SUKU",
    decimals: 18,
    logoURI: "/img/0x0763fdccf1ae541a5961815c0872a8c5bc6de4d7.jpg",
  },
  {
    chainId: 1,
    address: "0x6b3595068778dd592e39a122f4f5a5cf09c90fe2",
    name: "Sushi",
    symbol: "SUSHI",
    decimals: 18,
    logoURI: "/img/0x6b3595068778dd592e39a122f4f5a5cf09c90fe2.png",
  },
  {
    chainId: 1,
    address: "0x88df592f8eb5d7bd38bfef7deb0fbc02cf3778a0",
    name: "Tellor",
    symbol: "TRB",
    decimals: 18,
    logoURI: "/img/0x88df592f8eb5d7bd38bfef7deb0fbc02cf3778a0.png",
  },
  {
    chainId: 1,
    address: "0xc7283b66eb1eb5fb86327f08e1b5816b0720212b",
    name: "Tribe",
    symbol: "TRIBE",
    decimals: 18,
    logoURI: "/img/0xc7283b66eb1eb5fb86327f08e1b5816b0720212b.png",
  },
  {
    chainId: 1,
    address: "0x441761326490cacf7af299725b6292597ee822c2",
    name: "Unifi Protocol DAO",
    symbol: "UNFI",
    decimals: 18,
    logoURI: "/img/0x441761326490cacf7af299725b6292597ee822c2.png",
  },
  {
    chainId: 1,
    address: "0x3c4b6e6e1ea3d4863700d7f76b36b7f3d3f13e3d",
    name: "Voyager Token",
    symbol: "VGX",
    decimals: 8,
    logoURI: "/img/0x3c4b6e6e1ea3d4863700d7f76b36b7f3d3f13e3d.png",
  },
  {
    chainId: 1,
    address: "0x55296f69f40ea6d20e478533c15a6b08b654e758",
    name: "XYO Network",
    symbol: "XYO",
    decimals: 18,
    logoURI: "/img/0x55296f69f40ea6d20e478533c15a6b08b654e758.png",
  },
  {
    chainId: 1,
    address: "0xcc8fa225d80b9c7d42f96e9570156c65d6caaa25",
    name: "Smooth Love Potion",
    symbol: "SLP",
    decimals: 0,
    logoURI: "/img/0xcc8fa225d80b9c7d42f96e9570156c65d6caaa25.png",
  },
  {
    chainId: 1,
    address: "0x4e3fbd56cd56c3e72c1403e103b45db9da5b9d2b",
    name: "Convex Finance",
    symbol: "CVX",
    decimals: 18,
    logoURI: "/img/0x4e3fbd56cd56c3e72c1403e103b45db9da5b9d2b.png",
  },
  {
    chainId: 1,
    address: "0x92d6c1e31e14520e676a687f0a93788b716beff5",
    name: "dYdX",
    symbol: "DYDX",
    decimals: 18,
    logoURI: "/img/0x92d6c1e31e14520e676a687f0a93788b716beff5.jpg",
  },
  {
    chainId: 1,
    address: "0x3432b6a60d23ca0dfca7761b7ab56459d9c964d0",
    name: "Frax Share",
    symbol: "FXS",
    decimals: 18,
    logoURI: "/img/0x3432b6a60d23ca0dfca7761b7ab56459d9c964d0.png",
  },
  {
    chainId: 1,
    address: "0x1ceb5cb57c4d4e2b2433641b95dd330a33185a44",
    name: "Keep3rV1",
    symbol: "KP3R",
    decimals: 18,
    logoURI: "/img/0x1ceb5cb57c4d4e2b2433641b95dd330a33185a44.jpg",
  },
  {
    chainId: 1,
    address: "0x65ef703f5594d2573eb71aaf55bc0cb548492df4",
    name: "Multichain",
    symbol: "MULTI",
    decimals: 18,
    logoURI: "/img/0x65ef703f5594d2573eb71aaf55bc0cb548492df4.png",
  },
  {
    chainId: 1,
    address: "0x967da4048cd07ab37855c090aaf366e4ce1b9f48",
    name: "Ocean Protocol",
    symbol: "OCEAN",
    decimals: 18,
    logoURI: "/img/0x967da4048cd07ab37855c090aaf366e4ce1b9f48.jpg",
  },
  {
    chainId: 1,
    address: "0x45804880de22913dafe09f4980848ece6ecbaf78",
    name: "PAX Gold",
    symbol: "PAXG",
    decimals: 18,
    logoURI: "/img/0x45804880de22913dafe09f4980848ece6ecbaf78.png",
  },
  {
    chainId: 1,
    address: "0xed04915c23f00a313a544955524eb7dbd823143d",
    name: "Alchemy Pay",
    symbol: "ACH",
    decimals: 8,
    logoURI: "/img/0xed04915c23f00a313a544955524eb7dbd823143d.png",
  },
  {
    chainId: 1,
    address: "0x3d658390460295fb963f54dc0899cfb1c30776df",
    name: "Circuits of Value",
    symbol: "COVAL",
    decimals: 8,
    logoURI: "/img/0x3d658390460295fb963f54dc0899cfb1c30776df.png",
  },
  {
    chainId: 1,
    address: "0x3845badade8e6dff049820680d1f14bd3903a5d0",
    name: "The Sandbox",
    symbol: "SAND",
    decimals: 18,
    logoURI: "/img/0x3845badade8e6dff049820680d1f14bd3903a5d0.jpg",
  },
  {
    chainId: 1,
    address: "0x056fd409e1d7a124bd7017459dfea2f387b6d5cd",
    name: "Gemini Dollar",
    symbol: "GUSD",
    decimals: 2,
    logoURI: "/img/0x056fd409e1d7a124bd7017459dfea2f387b6d5cd.png",
  },
  {
    chainId: 1,
    address: "0x18aaa7115705e8be94bffebde57af9bfc265b998",
    name: "Audius",
    symbol: "AUDIO",
    decimals: 18,
    logoURI: "/img/0x18aaa7115705e8be94bffebde57af9bfc265b998.png",
  },
  {
    chainId: 1,
    address: "0x64d91f12ece7362f91a6f8e7940cd55f05060b92",
    name: "ASH",
    symbol: "ASH",
    decimals: 18,
    logoURI: "/img/0x64d91f12ece7362f91a6f8e7940cd55f05060b92.png",
  },
  {
    chainId: 1,
    address: "0x761d38e5ddf6ccf6cf7c55759d5210750b5d60f3",
    name: "Dogelon Mars",
    symbol: "ELON",
    decimals: 18,
    logoURI: "/img/0x761d38e5ddf6ccf6cf7c55759d5210750b5d60f3.jpg",
  },
  {
    chainId: 1,
    address: "0x4e15361fd6b4bb609fa63c81a2be19d873717870",
    name: "Fantom",
    symbol: "FTM",
    decimals: 18,
    logoURI: "/img/0x4e15361fd6b4bb609fa63c81a2be19d873717870.png",
  },
  {
    chainId: 1,
    address: "0xe28b3b32b6c345a34ff64674606124dd5aceca30",
    name: "Injective",
    symbol: "INJ",
    decimals: 18,
    logoURI: "/img/0xe28b3b32b6c345a34ff64674606124dd5aceca30.png",
  },
  {
    chainId: 1,
    address: "0x5a98fcbea516cf06857215779fd812ca3bef1b32",
    name: "Lido DAO",
    symbol: "LDO",
    decimals: 18,
    logoURI: "/img/0x5a98fcbea516cf06857215779fd812ca3bef1b32.png",
  },
  {
    chainId: 1,
    address: "0x99d8a9c45b2eca8864373a26d1459e3dff1e17f3",
    name: "Magic Internet Money",
    symbol: "MIM",
    decimals: 18,
    logoURI: "/img/0x99d8a9c45b2eca8864373a26d1459e3dff1e17f3.png",
  },
  {
    chainId: 1,
    address: "0x949d48eca67b17269629c7194f4b727d4ef9e5d6",
    name: "Merit Circle",
    symbol: "MC",
    decimals: 18,
    logoURI: "/img/0x949d48eca67b17269629c7194f4b727d4ef9e5d6.png",
  },
  {
    chainId: 1,
    address: "0x15d4c048f83bd7e37d49ea4c83a07267ec4203da",
    name: "Gala",
    symbol: "GALA",
    decimals: 8,
    logoURI: "/img/0x15d4c048f83bd7e37d49ea4c83a07267ec4203da.png",
  },
  {
    chainId: 1,
    address: "0xdf801468a808a32656d2ed2d2d80b72a129739f4",
    name: "Somnium Space CUBEs",
    symbol: "CUBE",
    decimals: 8,
    logoURI: "/img/0xdf801468a808a32656d2ed2d2d80b72a129739f4.png",
  },
  {
    chainId: 1,
    address: "0xac51066d7bec65dc4589368da368b212745d63e8",
    name: "My Neighbor Alice",
    symbol: "ALICE",
    decimals: 6,
    logoURI: "/img/0xac51066d7bec65dc4589368da368b212745d63e8.jpg",
  },
  {
    chainId: 1,
    address: "0x595832f8fc6bf59c85c527fec3740a1b7a361269",
    name: "Power Ledger",
    symbol: "POWR",
    decimals: 6,
    logoURI: "/img/0x595832f8fc6bf59c85c527fec3740a1b7a361269.png",
  },
  {
    chainId: 1,
    address: "0x1abaea1f7c830bd89acc67ec4af516284b1bc33c",
    name: "Euro Coin",
    symbol: "EUROC",
    decimals: 6,
    logoURI: "/img/0x1abaea1f7c830bd89acc67ec4af516284b1bc33c.png",
  },
  {
    chainId: 1,
    address: "0xb98d4c97425d9908e66e53a6fdf673acca0be986",
    name: "Arcblock",
    symbol: "ABT",
    decimals: 18,
    logoURI: "/img/0xb98d4c97425d9908e66e53a6fdf673acca0be986.png",
  },
  {
    chainId: 1,
    address: "0xade00c28244d5ce17d72e40330b1c318cd12b7c3",
    name: "Ambire AdEx",
    symbol: "ADX",
    decimals: 18,
    logoURI: "/img/0xade00c28244d5ce17d72e40330b1c318cd12b7c3.png",
  },
  {
    chainId: 1,
    address: "0x27702a26126e0b3702af63ee09ac4d1a084ef628",
    name: "Aleph im",
    symbol: "ALEPH",
    decimals: 18,
    logoURI: "/img/0x27702a26126e0b3702af63ee09ac4d1a084ef628.png",
  },
  {
    chainId: 1,
    address: "0x6b0b3a982b4634ac68dd83a4dbf02311ce324181",
    name: "Alethea Artificial Liquid Intelligence",
    symbol: "ALI",
    decimals: 18,
    logoURI: "/img/0x6b0b3a982b4634ac68dd83a4dbf02311ce324181.png",
  },
  {
    chainId: 1,
    address: "0xa1faa113cbe53436df28ff0aee54275c13b40975",
    name: "Alpha Venture DAO",
    symbol: "ALPHA",
    decimals: 18,
    logoURI: "/img/0xa1faa113cbe53436df28ff0aee54275c13b40975.png",
  },
  {
    chainId: 1,
    address: "0x27054b13b1b798b345b591a4d22e6562d47ea75a",
    name: "AirSwap",
    symbol: "AST",
    decimals: 4,
    logoURI: "/img/0x27054b13b1b798b345b591a4d22e6562d47ea75a.png",
  },
  {
    chainId: 1,
    address: "0xa2120b9e674d3fc3875f415a7df52e382f141225",
    name: "Automata",
    symbol: "ATA",
    decimals: 18,
    logoURI: "/img/0xa2120b9e674d3fc3875f415a7df52e382f141225.jpg",
  },
  {
    chainId: 1,
    address: "0x1a4b46696b2bb4794eb3d4c26f1c55f9170fa4c5",
    name: "BitDAO",
    symbol: "BIT",
    decimals: 18,
    logoURI: "/img/0x1a4b46696b2bb4794eb3d4c26f1c55f9170fa4c5.png",
  },
  {
    chainId: 1,
    address: "0x42bbfa2e77757c645eeaad1655e0911a7553efbc",
    name: "Boba Network",
    symbol: "BOBA",
    decimals: 18,
    logoURI: "/img/0x42bbfa2e77757c645eeaad1655e0911a7553efbc.png",
  },
  {
    chainId: 1,
    address: "0x4fabb145d64652a948d72533023f6e7a623c7c53",
    name: "Binance USD",
    symbol: "BUSD",
    decimals: 18,
    logoURI: "/img/0x4fabb145d64652a948d72533023f6e7a623c7c53.png",
    extensions: {
      bridgeInfo: {
        10: {
          tokenAddress: "0x9C9e5fD8bbc25984B178FdCE6117Defa39d2db39",
        },
      },
    },
  },
  {
    chainId: 1,
    address: "0xae12c5930881c53715b369cec7606b70d8eb229f",
    name: "Coin98",
    symbol: "C98",
    decimals: 18,
    logoURI: "/img/0xae12c5930881c53715b369cec7606b70d8eb229f.png",
  },
  {
    chainId: 1,
    address: "0x4f9254c83eb525f9fcf346490bbb3ed28a81c667",
    name: "Celer Network",
    symbol: "CELR",
    decimals: 18,
    logoURI: "/img/0x4f9254c83eb525f9fcf346490bbb3ed28a81c667.png",
  },
  {
    chainId: 1,
    address: "0x8a2279d4a90b6fe1c4b30fa660cc9f926797baa2",
    name: "Chromia",
    symbol: "CHR",
    decimals: 6,
    logoURI: "/img/0x8a2279d4a90b6fe1c4b30fa660cc9f926797baa2.png",
  },
  {
    chainId: 1,
    address: "0xd417144312dbf50465b1c641d016962017ef6240",
    name: "Covalent",
    symbol: "CQT",
    decimals: 18,
    logoURI: "/img/0xd417144312dbf50465b1c641d016962017ef6240.png",
  },
  {
    chainId: 1,
    address: "0x081131434f93063751813c619ecca9c4dc7862a3",
    name: "Mines of Dalarnia",
    symbol: "DAR",
    decimals: 6,
    logoURI: "/img/0x081131434f93063751813c619ecca9c4dc7862a3.png",
  },
  {
    chainId: 1,
    address: "0x3597bfd533a99c9aa083587b074434e61eb0a258",
    name: "Dent",
    symbol: "DENT",
    decimals: 8,
    logoURI: "/img/0x3597bfd533a99c9aa083587b074434e61eb0a258.png",
  },
  {
    chainId: 1,
    address: "0xfb7b4564402e5500db5bb6d63ae671302777c75a",
    name: "DexTools",
    symbol: "DEXT",
    decimals: 18,
    logoURI: "/img/0xfb7b4564402e5500db5bb6d63ae671302777c75a.png",
  },
  {
    chainId: 1,
    address: "0x1494ca1f11d487c2bbe4543e90080aeba4ba3c2b",
    name: "DeFi Pulse Index",
    symbol: "DPI",
    decimals: 18,
    logoURI: "/img/0x1494ca1f11d487c2bbe4543e90080aeba4ba3c2b.png",
  },
  {
    chainId: 1,
    address: "0x3ab6ed69ef663bd986ee59205ccad8a20f98b4c2",
    name: "Drep",
    symbol: "DREP",
    decimals: 18,
    logoURI: "/img/0x3ab6ed69ef663bd986ee59205ccad8a20f98b4c2.jpg",
  },
  {
    chainId: 1,
    address: "0x961c8c0b1aad0c0b10a51fef6a867e3091bcef17",
    name: "DeFi Yield Protocol",
    symbol: "DYP",
    decimals: 18,
    logoURI: "/img/0x961c8c0b1aad0c0b10a51fef6a867e3091bcef17.png",
  },
  {
    chainId: 1,
    address: "0xe6fd75ff38adca4b97fbcd938c86b98772431867",
    name: "Elastos",
    symbol: "ELA",
    decimals: 18,
    logoURI: "/img/0xe6fd75ff38adca4b97fbcd938c86b98772431867.png",
  },
  {
    chainId: 1,
    address: "0xd9fcd98c322942075a5c3860693e9f4f03aae07b",
    name: "Euler",
    symbol: "EUL",
    decimals: 18,
    logoURI: "/img/0xd9fcd98c322942075a5c3860693e9f4f03aae07b.jpg",
  },
  {
    chainId: 1,
    address: "0xef3a930e1ffffacd2fc13434ac81bd278b0ecc8d",
    name: "Stafi",
    symbol: "FIS",
    decimals: 18,
    logoURI: "/img/0xef3a930e1ffffacd2fc13434ac81bd278b0ecc8d.jpg",
  },
  {
    chainId: 1,
    address: "0x41545f8b9472d758bb669ed8eaeeecd7a9c4ec29",
    name: "Forta",
    symbol: "FORT",
    decimals: 18,
    logoURI: "/img/0x41545f8b9472d758bb669ed8eaeeecd7a9c4ec29.png",
  },
  {
    chainId: 1,
    address: "0x853d955acef822db058eb8505911ed77f175b99e",
    name: "Frax",
    symbol: "FRAX",
    decimals: 18,
    logoURI: "/img/0x853d955acef822db058eb8505911ed77f175b99e.png",
  },
  {
    chainId: 1,
    address: "0x5faa989af96af85384b8a938c2ede4a7378d9875",
    name: "Project Galaxy",
    symbol: "GAL",
    decimals: 18,
    logoURI: "/img/0x5faa989af96af85384b8a938c2ede4a7378d9875.png",
  },
  {
    chainId: 1,
    address: "0x3f382dbd960e3a9bbceae22651e88158d2791550",
    name: "Aavegotchi",
    symbol: "GHST",
    decimals: 18,
    logoURI: "/img/0x3f382dbd960e3a9bbceae22651e88158d2791550.png",
  },
  {
    chainId: 1,
    name: "HOPR",
    symbol: "HOPR",
    logoURI: "/img/0xf5581dfefd8fb0e4aec526be659cfab1f8c781da.png",
    address: "0xf5581dfefd8fb0e4aec526be659cfab1f8c781da",
    decimals: 18,
  },
  {
    chainId: 1,
    name: "Index Cooperative",
    symbol: "INDEX",
    logoURI: "/img/0x0954906da0bf32d5479e25f46056d22f08464cab.png",
    address: "0x0954906da0bf32d5479e25f46056d22f08464cab",
    decimals: 18,
  },
  {
    chainId: 1,
    name: "Geojam",
    symbol: "JAM",
    logoURI: "/img/0x23894dc9da6c94ecb439911caf7d337746575a72.jpg",
    address: "0x23894dc9da6c94ecb439911caf7d337746575a72",
    decimals: 18,
  },
  {
    chainId: 1,
    name: "Jupiter",
    symbol: "JUP",
    logoURI: "/img/0x4b1e80cac91e2216eeb63e29b957eb91ae9c2be8.png",
    address: "0x4b1e80cac91e2216eeb63e29b957eb91ae9c2be8",
    decimals: 18,
  },
  {
    chainId: 1,
    name: "SelfKey",
    symbol: "KEY",
    logoURI: "/img/0x4cc19356f2d37338b9802aa8e8fc58b0373296e7.png",
    address: "0x4cc19356f2d37338b9802aa8e8fc58b0373296e7",
    decimals: 18,
  },
  {
    chainId: 1,
    name: "League of Kingdoms",
    symbol: "LOKA",
    logoURI: "/img/0x61e90a50137e1f645c9ef4a0d3a4f01477738406.png",
    address: "0x61e90a50137e1f645c9ef4a0d3a4f01477738406",
    decimals: 18,
  },
  {
    chainId: 1,
    name: "Liquity USD",
    symbol: "LUSD",
    logoURI: "/img/0x5f98805a4e8be255a32880fdec7f6728c6568ba0.png",
    address: "0x5f98805a4e8be255a32880fdec7f6728c6568ba0",
    decimals: 18,
  },
  {
    chainId: 1,
    name: "MATH",
    symbol: "MATH",
    logoURI: "/img/0x08d967bb0134f2d07f7cfb6e246680c53927dd30.png",
    address: "0x08d967bb0134f2d07f7cfb6e246680c53927dd30",
    decimals: 18,
  },
  {
    chainId: 1,
    name: "Metis",
    symbol: "METIS",
    logoURI: "/img/0x9e32b13ce7f2e80a01932b42553652e053d6ed8e.jpg",
    address: "0x9e32b13ce7f2e80a01932b42553652e053d6ed8e",
    decimals: 18,
  },
  {
    chainId: 1,
    name: "Monavale",
    symbol: "MONA",
    logoURI: "/img/0x275f5ad03be0fa221b4c6649b8aee09a42d9412a.jpg",
    address: "0x275f5ad03be0fa221b4c6649b8aee09a42d9412a",
    decimals: 18,
  },
  {
    chainId: 1,
    name: "Metal",
    symbol: "MTL",
    logoURI: "/img/0xf433089366899d83a9f26a773d59ec7ecf30355e.png",
    address: "0xf433089366899d83a9f26a773d59ec7ecf30355e",
    decimals: 8,
  },
  {
    chainId: 1,
    name: "Muse DAO",
    symbol: "MUSE",
    logoURI: "/img/0xb6ca7399b4f9ca56fc27cbff44f4d2e4eef1fc81.png",
    address: "0xb6ca7399b4f9ca56fc27cbff44f4d2e4eef1fc81",
    decimals: 18,
  },
  {
    chainId: 1,
    name: "GensoKishi Metaverse",
    symbol: "MV",
    logoURI: "/img/0xae788f80f2756a86aa2f410c651f2af83639b95b.png",
    address: "0xae788f80f2756a86aa2f410c651f2af83639b95b",
    decimals: 18,
  },
  {
    chainId: 1,
    name: "MXC",
    symbol: "MXC",
    logoURI: "/img/0x5ca381bbfb58f0092df149bd3d243b08b9a8386e.png",
    address: "0x5ca381bbfb58f0092df149bd3d243b08b9a8386e",
    decimals: 18,
  },
  {
    chainId: 1,
    name: "Nest Protocol",
    symbol: "NEST",
    logoURI: "/img/0x04abeda201850ac0124161f037efd70c74ddc74c.png",
    address: "0x04abeda201850ac0124161f037efd70c74ddc74c",
    decimals: 18,
  },
  {
    chainId: 1,
    name: "Marlin",
    symbol: "POND",
    logoURI: "/img/0x57b946008913b82e4df85f501cbaed910e58d26c.png",
    address: "0x57b946008913b82e4df85f501cbaed910e58d26c",
    decimals: 18,
  },
  {
    chainId: 1,
    name: "PARSIQ",
    symbol: "PRQ",
    logoURI: "/img/0x362bc847a3a9637d3af6624eec853618a43ed7d2.png",
    address: "0x362bc847a3a9637d3af6624eec853618a43ed7d2",
    decimals: 18,
  },
  {
    chainId: 1,
    name: "pSTAKE Finance",
    symbol: "PSTAKE",
    logoURI: "/img/0xfb5c6815ca3ac72ce9f5006869ae67f18bf77006.png",
    address: "0xfb5c6815ca3ac72ce9f5006869ae67f18bf77006",
    decimals: 18,
  },
  {
    chainId: 1,
    name: "Qredo",
    symbol: "QRDO",
    logoURI: "/img/0x4123a133ae3c521fd134d7b13a2dec35b56c2463.png",
    address: "0x4123a133ae3c521fd134d7b13a2dec35b56c2463",
    decimals: 8,
  },
  {
    chainId: 1,
    name: "REVV",
    symbol: "REVV",
    logoURI: "/img/0x557b933a7c2c45672b610f8954a3deb39a51a8ca.png",
    address: "0x557b933a7c2c45672b610f8954a3deb39a51a8ca",
    decimals: 18,
  },
  {
    chainId: 1,
    name: "Rook",
    symbol: "ROOK",
    logoURI: "/img/0xfa5047c9c78b8877af97bdcb85db743fd7313d4a.jpg",
    address: "0xfa5047c9c78b8877af97bdcb85db743fd7313d4a",
    decimals: 18,
  },
  {
    chainId: 1,
    name: "Stargate Finance",
    symbol: "STG",
    logoURI: "/img/0xaf5191b0de278c7286d6c7cc6ab6bb8a73ba2cd6.png",
    address: "0xaf5191b0de278c7286d6c7cc6ab6bb8a73ba2cd6",
    decimals: 18,
  },
  {
    chainId: 1,
    name: "SWFTCOIN",
    symbol: "SWFTC",
    logoURI: "/img/0x0bb217e40f8a5cb79adf04e1aab60e5abd0dfc1e.jpg",
    address: "0x0bb217e40f8a5cb79adf04e1aab60e5abd0dfc1e",
    decimals: 8,
  },
  {
    chainId: 1,
    name: "Swipe",
    symbol: "SXP",
    logoURI: "/img/0x8ce9137d39326ad0cd6491fb5cc0cba0e089b6a9.png",
    address: "0x8ce9137d39326ad0cd6491fb5cc0cba0e089b6a9",
    decimals: 18,
  },
  {
    chainId: 1,
    name: "Sylo",
    symbol: "SYLO",
    logoURI: "/img/0xf293d23bf2cdc05411ca0eddd588eb1977e8dcd4.svg",
    address: "0xf293d23bf2cdc05411ca0eddd588eb1977e8dcd4",
    decimals: 18,
  },
  {
    chainId: 1,
    name: "Threshold Network",
    symbol: "T",
    logoURI: "/img/0xcdf7028ceab81fa0c6971208e83fa7872994bee5.jpg",
    address: "0xcdf7028ceab81fa0c6971208e83fa7872994bee5",
    decimals: 18,
  },
  {
    chainId: 1,
    name: "ChronoTech",
    symbol: "TIME",
    logoURI: "/img/0x485d17a6f1b8780392d53d64751824253011a260.png",
    address: "0x485d17a6f1b8780392d53d64751824253011a260",
    decimals: 8,
  },
  {
    chainId: 1,
    name: "Alien Worlds",
    symbol: "TLM",
    logoURI: "/img/0x888888848b652b3e3a0f34c96e00eec0f3a23f72.jpg",
    address: "0x888888848b652b3e3a0f34c96e00eec0f3a23f72",
    decimals: 4,
  },
  {
    chainId: 1,
    name: "TE FOOD",
    symbol: "TONE",
    logoURI: "/img/0x2ab6bb8408ca3199b8fa6c92d5b455f820af03c4.png",
    address: "0x2ab6bb8408ca3199b8fa6c92d5b455f820af03c4",
    decimals: 18,
  },
  {
    chainId: 1,
    name: "The Virtua Kolect",
    symbol: "TVK",
    logoURI: "/img/0xd084b83c305dafd76ae3e1b4e1f1fe2ecccb3988.png",
    address: "0xd084b83c305dafd76ae3e1b4e1f1fe2ecccb3988",
    decimals: 18,
  },
  {
    chainId: 1,
    name: "Wrapped Ampleforth",
    symbol: "WAMPL",
    logoURI: "/img/0xedb171c18ce90b633db442f2a6f72874093b49ef.jpg",
    address: "0xedb171c18ce90b633db442f2a6f72874093b49ef",
    decimals: 18,
  },
  {
    chainId: 1,
    name: "Wrapped Centrifuge",
    symbol: "WCFG",
    logoURI: "/img/0xc221b7e65ffc80de234bbb6667abdd46593d34f0.jpg",
    address: "0xc221b7e65ffc80de234bbb6667abdd46593d34f0",
    decimals: 18,
  },
  {
    chainId: 1,
    name: "WOO Network",
    symbol: "WOO",
    logoURI: "/img/0x4691937a7508860f876c9c0a2a617e7d9e945d4b.jpg",
    address: "0x4691937a7508860f876c9c0a2a617e7d9e945d4b",
    decimals: 18,
  },
  {
    chainId: 1,
    name: "Chain",
    symbol: "XCN",
    logoURI: "/img/0xa2cd3d43c775978a96bdbf12d733d5a1ed94fb18.png",
    address: "0xa2cd3d43c775978a96bdbf12d733d5a1ed94fb18",
    decimals: 18,
  },
  {
    chainId: 1,
    name: "Yield Guild Games",
    symbol: "YGG",
    logoURI: "/img/0x25f8087ead173b73d6e8b84329989a8eea16cf73.jpg",
    address: "0x25f8087ead173b73d6e8b84329989a8eea16cf73",
    decimals: 18,
  },
  {
    chainId: 1,
    address: "0x23b608675a2b2fb1890d3abbd85c5775c51691d5",
    name: "Unisocks",
    symbol: "SOCKS",
    decimals: 18,
    logoURI: "/img/0x23b608675a2b2fb1890d3abbd85c5775c51691d5.png",
  },
  {
    chainId: 1,
    address: "0x5283d291dbcf85356a21ba090e6db59121208b44",
    name: "Blur",
    symbol: "BLUR",
    decimals: 18,
    logoURI: "/img/0x5283d291dbcf85356a21ba090e6db59121208b44.png",
  },
  {
    chainId: 1,
    address: "0x467719ad09025fcc6cf6f8311755809d45a5e5f3",
    name: "Axelar",
    symbol: "AXL",
    decimals: 6,
    logoURI: "/img/0x467719ad09025fcc6cf6f8311755809d45a5e5f3.jpg",
    extensions: {
      bridgeInfo: {
        42161: {
          tokenAddress: "0x23ee2343B892b1BB63503a4FAbc840E0e2C6810f",
        },
      },
    },
  },
  {
    chainId: 1,
    address: "0x767fe9edc9e0df98e07454847909b5e959d7ca0e",
    name: "Illuvium",
    symbol: "ILV",
    decimals: 18,
    logoURI: "/img/0x767fe9edc9e0df98e07454847909b5e959d7ca0e.jpg",
  },
  {
    chainId: 1,
    address: "0xb3999f658c0391d94a37f7ff328f3fec942bcadc",
    name: "Hashflow",
    symbol: "HFT",
    decimals: 18,
    logoURI: "/img/0xb3999f658c0391d94a37f7ff328f3fec942bcadc.png",
  },
  {
    chainId: 1,
    name: "Arbitrum",
    address: "0xb50721bcf8d664c30412cfbc6cf7a15145234ad1",
    symbol: "ARB",
    decimals: 18,
    logoURI: "/img/0xb50721bcf8d664c30412cfbc6cf7a15145234ad1.png",
  },
  {
    chainId: 1,
    address: "0xbe9895146f7af43049ca1c1ae358b0541ea49704",
    name: "Coinbase Wrapped Staked ETH",
    symbol: "cbETH",
    decimals: 18,
    logoURI: "/img/0xbe9895146f7af43049ca1c1ae358b0541ea49704.png",
  },
  {
    chainId: 1,
    address: "0x6982508145454ce325ddbe47a25d4ec3d2311933",
    name: "Pepe",
    symbol: "PEPE",
    decimals: 18,
    logoURI: "/img/0x6982508145454ce325ddbe47a25d4ec3d2311933.jpg",
  },
  {
    chainId: 1,
    address: "0xb23d80f5fefcddaa212212f028021b41ded428cf",
    name: "Prime",
    symbol: "PRIME",
    decimals: 18,
    logoURI: "/img/0xb23d80f5fefcddaa212212f028021b41ded428cf.png",
  },
  {
    chainId: 1,
    address: "0x6c3ea9036406852006290770bedfcaba0e23a0e8",
    name: "PayPal USD",
    symbol: "PYUSD",
    decimals: 6,
    logoURI: "/img/0x6c3ea9036406852006290770bedfcaba0e23a0e8.png",
  },
  {
    chainId: 1,
    address: "0x3294395e62f4eb6af3f1fcf89f5602d90fb3ef69",
    name: "Celo native asset (Wormhole)",
    symbol: "CELO",
    decimals: 18,
    logoURI: "/img/0x3294395e62f4eb6af3f1fcf89f5602d90fb3ef69.png",
    extensions: {
      bridgeInfo: {
        10: {
          tokenAddress: "0x9b88D293b7a791E40d36A39765FFd5A1B9b5c349",
        },
        42161: {
          tokenAddress: "0x4E51aC49bC5e2d87e0EF713E9e5AB2D71EF4F336",
        },
      },
    },
  },
  {
    chainId: 1,
    address: "0x1a7e4e63778b4f12a199c062f3efdd288afcbce8",
    name: "agEur",
    symbol: "agEUR",
    decimals: 18,
    logoURI: "/img/0x1a7e4e63778b4f12a199c062f3efdd288afcbce8.png",
    extensions: {
      bridgeInfo: {
        56: {
          tokenAddress: "0x12f31B73D812C6Bb0d735a218c086d44D5fe5f89",
        },
        137: {
          tokenAddress: "0xE0B52e49357Fd4DAf2c15e02058DCE6BC0057db4",
        },
        42161: {
          tokenAddress: "0xFA5Ed56A203466CbBC2430a43c66b9D8723528E7",
        },
        43114: {
          tokenAddress: "0xAEC8318a9a59bAEb39861d10ff6C7f7bf1F96C57",
        },
      },
    },
  },
  {
    chainId: 1,
    address: "0x70e8de73ce538da2beed35d14187f6959a8eca96",
    name: "XSGD",
    symbol: "XSGD",
    decimals: 6,
    logoURI: "/img/0x70e8de73ce538da2beed35d14187f6959a8eca96.png",
    extensions: {
      bridgeInfo: {
        137: {
          tokenAddress: "0xDC3326e71D45186F113a2F448984CA0e8D201995",
        },
      },
    },
  },
  {
    chainId: 1,
    address: "0x64bc2ca1be492be7185faa2c8835d9b824c8a194",
    name: "Big Time",
    symbol: "BIGTIME",
    decimals: 18,
    logoURI: "/img/0x64bc2ca1be492be7185faa2c8835d9b824c8a194.jpg",
  },
  {
    chainId: 1,
    address: "0x3fC91A3afd70395Cd496C647d5a6CC9D4B2b7FAD",
    name: "Universal Router",
    symbol: "Universal Router",
    decimals: "Universal Router",
    logoURI: "img/router.png",
  },
];
