import ForceGraph2D from "react-force-graph-2d";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import React, { useState, useEffect, useCallback } from "react";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import {
  useQuery,
  ApolloClient,
  InMemoryCache,
  useSubscription,
  split,
  HttpLink
} from "@apollo/client";
import {
  graphData,
  poolGraphData,
  blockNodeToBlockArrowData,
  filterDuplicateArrow,
  filterDuplicateNode,
} from "./dataConverter";
import { l1query, poolSubscription, l1BulkSubscription } from "./query";
import { imgMaker, handleClick, labelMaker } from "./customizer";

import { Item } from "../pages/Style/styled";




// GraphQL settings
const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_GRAPHQL_WS_URL ?? "ws://localhost:4000/graphql"
  })
);
// Use useQuery to fetch data in your component 
const limitGraphNodes = 3000;

const httpLink = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_HTTP_URL ?? "http://localhost:4000/graphql"
});
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);
// Initialize Apollo Client
const cache = new InMemoryCache({
  typePolicies: {
    Subscription: {
      fields: {
        txnBulk: {
          merge(existing = [], incoming = []) {
            const merged = existing.concat(incoming); 
            return merged;
          },
        },
      },
    },
  },
});
const client = new ApolloClient({
  link: splitLink,
  cache
});

const lightTheme = createTheme({ palette: { mode: "light" } });

const loadingPage = (
  <Box
    sx={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    }}
  >
    <CircularProgress color="primary" />
  </Box>
);

const errorPage = (
  <Box
    sx={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    }}
  >
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      Something Happened on Our End —{" "}
      <strong>
        If this error persists, please contact us through GitHub or X.
      </strong>
    </Alert>
  </Box>
);

// *************************************************************
// ******************< Application Logic >************************
// *************************************************************

const GraphApp = ({
  nodeNumber,
  queryNumber,
  txSubscribe,
  txpoolSubscribe,
  trackValue,
  count,
  detailedOutput,
}) => {
  const {
    data: initialData,
    loading: initialLoading,
    error: initialError,
  } = useQuery(
    l1query(queryNumber <= 100 && queryNumber > 0 ? queryNumber : 15),
    { client }
  );
  // Loading Page
  if (initialLoading) return loadingPage;
  // Error Page
  if (initialError) return errorPage;
  return (
    <LiveGraph
      initialData={initialData}
      nodeNumber={nodeNumber}
      txSubscribe={txSubscribe}
      txpoolSubscribe={txpoolSubscribe}
      trackValue={trackValue}
      count={count}
      detailedOutput={detailedOutput}
    />
  );
};

const LiveGraph = ({
  initialData,
  nodeNumber,
  txSubscribe,
  txpoolSubscribe,
  trackValue,
  count,
  detailedOutput
}) => {
  const maxGraphNodes =
    nodeNumber > limitGraphNodes ? limitGraphNodes : nodeNumber;
  // L1 Data Subscription
  const {
    data: subscriptionData,
    loading: subscriptionLoading,
    error: subscriptionError,
  } = useSubscription(l1BulkSubscription(), { client, skip: txSubscribe });
  // Txpool Data Subscription
  const {
    data: subscriptionData2,
    loading: subscriptionLoading2,
    error: subscriptionError2,
  } = useSubscription(poolSubscription(), { client, skip: txpoolSubscribe });
  // Initialize with query data
  const [data, setData] = useState(graphData(initialData, detailedOutput));

  // Making L1 Subscription graph
  useEffect(() => {
    // Subscription data is received when subscriptionData changes
    if (subscriptionData) {
      const newData = graphData(subscriptionData, detailedOutput);
      setData((prevData) => {
        const mergedNodeData = filterDuplicateNode([
          ...prevData.nodes,
          ...newData.nodes,
        ]); //Delete duplication Objects
        // Block Arrow creation
        const newBlockToBlockArrowArray = blockNodeToBlockArrowData(
          filterDuplicateNode([...prevData.nodes, ...newData.nodes])
        );
        const mergedArrowData = filterDuplicateArrow([
          ...prevData.links,
          ...newData.links,
          ...newBlockToBlockArrowArray,
        ]);
        const mergedData = { nodes: mergedNodeData, links: mergedArrowData };
        // Track Path Renew
        trackNode();
        return mergedData.nodes.length > maxGraphNodes ? newData : mergedData; //Remove nodes and arrows when reached limit
      });
    }
  }, [subscriptionData]);

  // Making Txpool graph
  useEffect(() => {
    // Subscription data is received when subscriptionData changes
    if (subscriptionData2) {
      const newData = poolGraphData(subscriptionData2, detailedOutput);
      setData((prevData) => {
        const mergedNodeData = filterDuplicateNode([
          ...prevData.nodes,
          ...newData.nodes,
        ]); //Delete duplication Objects
        // Block Arrow creation
        const newBlockToBlockArrowArray = blockNodeToBlockArrowData(
          filterDuplicateNode([...prevData.nodes, ...newData.nodes])
        );
        const mergedArrowData = filterDuplicateArrow([
          ...prevData.links,
          ...newData.links,
          ...newBlockToBlockArrowArray,
        ]);
        const mergedData = { nodes: mergedNodeData, links: mergedArrowData };
        // Track Path Renew
        trackNode();
        return mergedData.nodes.length > maxGraphNodes ? newData : mergedData; //Remove nodes and arrows when reached limit
      });
    }
  }, [subscriptionData2]);

  // Command Path High Light Logic
  const NODE_R = 7;
  const [highlightNodes, setHighlightNodes] = useState(new Set());
  const [hoverNode, setHoverNode] = useState(null);

  const [highlightLinks, setHighlightLinks] = useState(new Set());
  const updateHighlight = () => {
    setHighlightNodes(highlightNodes);
    setHighlightLinks(highlightLinks);
  };

  const handleLinkHover = (link) => {
    highlightNodes.clear();
    highlightLinks.clear();
    if (link) {
      highlightLinks.add(link);
      data.links.map((i) =>
        i.group === link.group ? highlightLinks.add(i) : null
      );
    }
    updateHighlight();
  };

  const handleNodeHover = (node) => {
    highlightNodes.clear();
    highlightLinks.clear();
    if (node) {
      highlightNodes.add(node);
      data.links.map((i) =>
        data.links.map((i2) =>
          (node.id === i.target.id ||
            node.id === i.source.id ||
            `block_${node.id}` === i.target.id ||
            `block_${node.id}` === i.source.id ||
            `hash_${node.id}` === i.target.id ||
            `hash_${node.id}` === i.source.id ||
            `from_${trackValue}` === i.target.id ||
            `from_${trackValue}` === i.source.id) &&
          i2.group === i.group
            ? highlightLinks.add(i2)
            : null
        )
      );
    }
    setHoverNode(node || null);
    updateHighlight();
  };

  const paintRing = useCallback(
    (node, ctx, globalScale) => {
      // add ring just for highlighted nodes
      ctx.arc(node.x, node.y, NODE_R * 1.4, 0, 2 * Math.PI, false);
      ctx.fillStyle =
        node === hoverNode ||
        node.id === trackValue ||
        node.id === `block_${trackValue}` ||
        node.id === `hash_${trackValue}` ||
        node.id === `from_${trackValue}`
          ? "lightcoral"
          : "white";
      ctx.fill();
      ctx.beginPath();
      imgMaker(node, ctx, globalScale, trackValue);
    },
    [hoverNode, count]
  );

  // Highlight by text area designation
  const [highlightNodes2, setHighlightNodes2] = useState(new Set());
  const [highlightLinks2, setHighlightLinks2] = useState(new Set());
  const updateHighlight2 = () => {
    setHighlightNodes2(highlightNodes2);
    setHighlightLinks2(highlightLinks2);
  };

  const trackNode = () => {
    highlightNodes2.clear();
    highlightLinks2.clear();
    data.links.map((i) =>
      data.links.map((i2) =>
        (trackValue === i.target.id ||
          trackValue === i.source.id ||
          `block_${trackValue}` === i.target.id ||
          `block_${trackValue}` === i.source.id ||
          `hash_${trackValue}` === i.target.id ||
          `hash_${trackValue}` === i.source.id ||
          `from_${trackValue}` === i.target.id ||
          `from_${trackValue}` === i.source.id) &&
        i2.group === i.group
          ? highlightLinks2.add(i2)
          : null
      )
    );
    updateHighlight2();
  };
  // for update highlight when tracking address or txhash
  useEffect(() => {
    trackNode();
  }, [count]);

  if (subscriptionLoading || subscriptionLoading2)
    console.log("Loading subscription...");
  if (subscriptionError || subscriptionError2)
    console.log(
      "Subscription Error! Make sure your environment permits websocket communication!"
    );

  return (
    <>
      <ThemeProvider theme={lightTheme}>
        <Item key={24} elevation={4}>
          Node: {data.nodes.length/maxGraphNodes>0.8?<font color="red">{data.nodes.length}</font>:data.nodes.length}/{maxGraphNodes} | TxPool:{" "}
          {!txpoolSubscribe ? "On" : <font color="red">Off</font>} | Tx:{" "}
          {!txSubscribe ? "On" : <font color="red">Off</font>}
        </Item>
      </ThemeProvider>
      <ForceGraph2D
        graphData={data}
        // node on label
        nodeLabel={(node) => labelMaker(node)}
        // link on Text
        linkDirectionalArrowLength={5}
        linkDirectionalArrowRelPos={0.85}
        linkCurvature={0}
        linkLabel="value"
        // Adding icon
        nodeCanvasObject={(node, ctx, globalScale) =>
          paintRing(node, ctx, globalScale)
        }
        onNodeClick={(node) => handleClick(node)}
        autoPauseRedraw={false}
        linkWidth={(link) =>
          highlightLinks.has(link) ? 5 : highlightLinks2.has(link) ? 5 : 1
        }
        linkDirectionalParticles={4}
        linkDirectionalParticleWidth={(link) =>
          highlightLinks.has(link) ? 4 : highlightLinks2.has(link) ? 4 : 0
        }
        linkDirectionalParticleSpeed={0.02}
        onLinkHover={(link) => handleLinkHover(link)}
        onNodeDragEnd={(node) => {
          node.fx = node.x;
          node.fy = node.y;
        }}
        linkDirectionalParticleColor={() => "lightcoral"}
        onNodeHover={(node) => handleNodeHover(node)}
      />
    </>
  );
};
export default GraphApp;
