import {gql} from '@apollo/client';

export const l1query = (limit) => gql`
query {
  txnList (limit: ${limit}) {
    provider
    blockNumber
    blockHash
    hash
    type
    to
    from
    nonce
    gasLimit
    gasPrice
    maxPriorityFeePerGas
    maxFeePerGas
    data
    value
    chainId
    createdAt
    decodedData {
      contents
      deadline
    }
    accessList
    blockHeader {
     _type
     baseFeePerGas
     difficulty
     extraData
     gasLimit
     gasUsed
     hash
     miner
     nonce
     number
     parentHash
     timestamp
    }
  }  
}
`;

export const l2query = (limit) => gql`
query {
  l2txnList (limit: ${limit}) {
    provider
    blockNumber
    blockHash
    hash
    type
    to
    from
    nonce
    gasLimit
    gasPrice
    maxPriorityFeePerGas
    maxFeePerGas
    data
    value
    chainId
    createdAt
    decodedData {
      contents
      deadline
    }
    accessList
    blockHeader {
     _type
     baseFeePerGas
     difficulty
     extraData
     gasLimit
     gasUsed
     hash
     miner
     nonce
     number
     parentHash
     timestamp
    }
  }  
}
`;

export const poolSubscription = () => gql`
subscription {
  txnPool {
    provider
    blockNumber
    blockHash
    hash
    type
    to
    from
    nonce
    gasLimit
    gasPrice
    maxPriorityFeePerGas
    maxFeePerGas
    data
    value
    chainId
    createdAt
    decodedData {
      contents
      deadline
    }
    accessList
  }  
}
`;

export const l1Subscription = () => gql`
subscription {
  txn {
    provider
    blockNumber
    blockHash
    hash
    type
    to
    from
    nonce
    gasLimit
    gasPrice
    maxPriorityFeePerGas
    maxFeePerGas
    data
    value
    chainId
    createdAt
    decodedData {
      contents
      deadline
    }
    accessList
    blockHeader {
     _type
     baseFeePerGas
     difficulty
     extraData
     gasLimit
     gasUsed
     hash
     miner
     nonce
     number
     parentHash
     timestamp
    }
  }  
}
`;

export const l1BulkSubscription = () => gql`
subscription {
  txnBulk {
    provider
    blockNumber
    blockHash
    hash
    type
    to
    from
    nonce
    gasLimit
    gasPrice
    maxPriorityFeePerGas
    maxFeePerGas
    data
    value
    chainId
    createdAt
    decodedData {
      contents
      deadline
    }
    accessList
    blockHeader {
     _type
     baseFeePerGas
     difficulty
     extraData
     gasLimit
     gasUsed
     hash
     miner
     nonce
     number
     parentHash
     timestamp
    }
  }  
}
`;

export const l2Subscription = () => gql`
subscription {
  l2txn {
    provider
    blockNumber
    blockHash
    hash
    type
    to
    from
    nonce
    gasLimit
    gasPrice
    maxPriorityFeePerGas
    maxFeePerGas
    data
    value
    chainId
    createdAt
    decodedData {
      contents
      deadline
    }
    accessList
    blockHeader {
     _type
     baseFeePerGas
     difficulty
     extraData
     gasLimit
     gasUsed
     hash
     miner
     nonce
     number
     parentHash
     timestamp
    }
  }  
}
`;


export const l2BulkSubscription = () => gql`
subscription {
  l2txnBulk {
    provider
    blockNumber
    blockHash
    hash
    type
    to
    from
    nonce
    gasLimit
    gasPrice
    maxPriorityFeePerGas
    maxFeePerGas
    data
    value
    chainId
    createdAt
    decodedData {
      contents
      deadline
    }
    accessList
    blockHeader {
     _type
     baseFeePerGas
     difficulty
     extraData
     gasLimit
     gasUsed
     hash
     miner
     nonce
     number
     parentHash
     timestamp
    }
  }  
}
`;
