import { tokenList } from "./tokenList";
const tokenSymbol = (address) => {
  const tokenListing = tokenList();
  const matchedData = tokenListing.filter(
    (asset) => parseInt(asset.address) === parseInt(address)
  );
  const name = matchedData.length > 0 ? matchedData[0]["name"] : address;
  return name;
};
const tokenImage = (address) => {
  const tokenListing = tokenList();
  const matchedData = tokenListing.filter(
    (asset) => parseInt(asset.address) === parseInt(address)
  );
  const src =
    matchedData.length > 0 ? matchedData[0]["logoURI"] : "img/address.svg";
  return src;
};
export const imgMaker = (node, ctx, globalScale, trackValue) => {
  const img = new Image();

  if (node.node_name === "block") {
    img.src = "img/blockchain.svg";
    ctx.drawImage(img, node.x - 15, node.y - 15, 30, 30);
  }

  if (node.node_name === "user") {
    img.src = "img/user.svg";
    ctx.drawImage(img, node.x - 12, node.y - 9, 24, 18);
  }

  if (node.node_name === "transaction") {
    img.src = "img/transaction.svg";
    ctx.drawImage(img, node.x - 10, node.y - 10, 20, 20);
  }

  if (
    node.node_name === "recipient" ||
    node.node_name === "path" ||
    node.node_name === "token" ||
    node.node_name === "spender"
  ) {
    img.src = tokenImage(node.value);
    // draw normal address image
    tokenImage(node.value) === "img/address.svg"
      ? ctx.drawImage(img, node.x - 7, node.y - 5, 14, 10)
      : ctx.drawImage(img, node.x - 8, node.y - 8, 16, 16);
  }

  if (
    node.node_name === "amount" ||
    node.node_name === "min_amount" ||
    node.node_name === "max_amount" ||
    node.node_name === "minimum_required_token"
  ) {
    img.src = "img/amount.svg";
    ctx.drawImage(img, node.x - 5, node.y - 5, 10, 10);
  }

  if (node.node_name === "is_msg_sender" && node.value === "true") {
    img.src = "img/true.svg";
    ctx.drawImage(img, node.x - 7, node.y - 5, 14, 10);
  }

  if (node.node_name === "is_msg_sender" && node.value === "false") {
    img.src = "img/false.svg";
    ctx.drawImage(img, node.x - 7, node.y - 5, 14, 10);
  }

  if (node.node_name === "bp_percentage") {
    img.src = "img/bp_percentage.svg";
    ctx.drawImage(img, node.x - 5, node.y - 5, 10, 10);
  }

  if (
    node.node_name === "expiration" ||
    node.node_name === "sig_deadline" ||
    node.node_name === "deadline"
  ) {
    img.src = "img/expiration.svg";
    ctx.drawImage(img, node.x - 7, node.y - 5, 14, 10);
  }

  if (node.node_name === "nonce") {
    img.src = "img/nonce.svg";
    ctx.drawImage(img, node.x - 5, node.y - 5, 10, 10);
  }

  if (node.node_name === "signature") {
    img.src = "img/signature.svg";
    ctx.drawImage(img, node.x - 5, node.y - 5, 10, 10);
  }

  if (node.node_name === "fee") {
    img.src = "img/fee.svg";
    ctx.drawImage(img, node.x - 5, node.y - 5, 10, 10);
  }

  if (/^command/.test(node.node_name)) {
    img.src = "img/command.svg";
    ctx.drawImage(img, node.x - 7, node.y - 5, 14, 10);
  }
};

export const handleClick = (node) => {
  //Etherescan URL
  const blkExplorer = "https://etherscan.io";

  if (node.node_name === "block") {
    window.open(`${blkExplorer}/block/${node.value}`);
  }

  if (node.node_name === "transaction") {
    window.open(`${blkExplorer}/tx/${node.value}`);
  }

  if (
    node.node_name === "user" ||
    node.node_name === "recipient" ||
    node.node_name === "path" ||
    node.node_name === "token" ||
    node.node_name === "spender"
  ) {
    window.open(`${blkExplorer}/address/${node.value}`);
  }
};

export const labelMaker = (node) => {
  if (node.node_name === "block") {
    return `<table border="1">
    <thead style="text-align:center"><tr><th colspan="2">Block</th></tr></thead>
    <tr><td nowrap><i class="fa-solid fa-cubes"></i>&nbsp;blockNumber</td><td>${
      node.meta.number
    }</td></tr>
    <tr><td nowrap><i class="fa-solid fa-hashtag"></i>&nbsp;hash</td><td>${
      node.meta.hash
    }</td></tr>
    <tr><td nowrap><i class="fa-solid fa-hashtag"></i>&nbsp;parentHash</td><td>${
      node.meta.parentHash
    }</td></tr>
    <tr><td nowrap><i class="fa-solid fa-gas-pump"></i>&nbsp;baseFeePerGas</td><td>${
      node.meta.baseFeePerGas
    }</td></tr>
    <tr><td nowrap><i class="fa-solid fa-gas-pump"></i>&nbsp;gasLimit</td><td>${
      node.meta.gasLimit
    }</td></tr>
    <tr><td nowrap><i class="fa-solid fa-gas-pump"></i>&nbsp;gasUsed</td><td>${
      node.meta.gasUsed
    }</td></tr>
    <tr><td nowrap><i class="fa-regular fa-clock"></i>&nbsp;timestamp</td><td>${new Date(
      node.meta.timestamp * 1000
    )}</td></tr></table>
    `;
  }

  if (node.node_name === "transaction") {
    return `<table border="1">
    <thead style="text-align:center"><tr><th colspan="2">Transaction</th></tr></thead>
    <tr><td nowrap><i class="fa-solid fa-hashtag"></i>&nbsp;hash</td><td>${
      node.value
    }</td></tr>
    <tr><td nowrap><i class="fa-solid fa-cubes"></i>&nbsp;blockNumber</td><td>${(node.meta.blockNumber =
      node.meta.blockNumber === null
        ? "Pending"
        : node.meta.blockNumber)}</td></tr>
    <tr><td nowrap><i class="fa-solid fa-hashtag"></i>&nbsp;type</td><td>${(node.meta.type =
      parseInt(node.meta.type) === 2 ? "2 (EIP-1559)" : "1 (Legacy)")}</td></tr>
    <tr><td nowrap><i class="fa-solid fa-hashtag"></i>&nbsp;nonce</td><td>${
      node.meta.nonce
    }</td></tr>
    <tr><td nowrap><i class="fa-solid fa-gas-pump"></i>&nbsp;gasLimit</td><td>${
      node.meta.gasLimit
    }</td></tr>
    <tr><td nowrap><i class="fa-solid fa-gas-pump"></i>&nbsp;gasPrice</td><td>${
      node.meta.gasPrice
    }</td></tr>
    <tr><td nowrap><i class="fa-solid fa-gas-pump"></i>&nbsp;maxPriorityFeePerGas</td><td>${
      node.meta.maxPriorityFeePerGas === null
        ? "No data"
        : node.meta.maxPriorityFeePerGas
    }</td></tr>
    <tr><td nowrap><i class="fa-solid fa-gas-pump"></i>&nbsp;maxFeePerGas</td><td>${
      node.meta.maxFeePerGas === null
        ? "No data"
        : node.meta.maxFeePerGas
    }</td></tr>
    <tr><td nowrap><i class="fa-solid fa-coins"></i>&nbsp;value</td><td>${
      parseFloat(node.meta.value) / 1000000000000000000
    } ETH</td></tr></table>
    `;
  }

  if (node.node_name === "user") {
    return `Trader: ${tokenSymbol(node.value)}`;
  }

  if (
    node.node_name === "recipient" ||
    node.node_name === "path" ||
    node.node_name === "token" ||
    node.node_name === "spender"
  ) {
    return `Address: ${tokenSymbol(node.value)}`;
  }

  if (
    node.node_name === "amount" ||
    node.node_name === "min_amount" ||
    node.node_name === "max_amount" ||
    node.node_name === "minimum_required_token"
  ) {
    const capitalizeFirstLetter = (str) =>
      str.charAt(0).toUpperCase() + str.slice(1);
    return `${capitalizeFirstLetter(node.node_name)}: ${node.value}`;
  }

  if (node.node_name === "is_msg_sender" && node.value === "true") {
    return `IsMsgSender?: Yes!(${node.value})`;
  }

  if (node.node_name === "is_msg_sender" && node.value === "false") {
    return `IsMsgSender?: No!(${node.value})`;
  }

  if (node.node_name === "bp_percentage") {
    return `BalanceToTransfer(%:BasisPoint): ${node.value}`;
  }

  if (node.node_name === "expiration") {
    return `PermitSingleExpiration: ${new Date(node.value * 1000)}`;
  }

  if (node.node_name === "sig_deadline") {
    return `PermitSingleSigDeadline: ${new Date(node.value * 1000)}`;
  }

  if (node.node_name === "deadline") {
    return `TransactionDeadline: ${new Date(node.value * 1000)}`;
  }

  if (node.node_name === "nonce") {
    return `PermitSingleNonce: ${node.value}`;
  }

  if (node.node_name === "signature") {
    return `Permit2Signature: ${node.value}`;
  }

  if (node.node_name === "fee") {
    return `PoolFee: ${node.value}`;
  }

  if (/^command/.test(node.node_name)) {
    return `Command: ${node.value}`;
  }
  // else
  return node.value;
};
