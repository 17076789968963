import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Button,
  Grid,
  TextField,
  FormControl,
  ButtonGroup,
  IconButton,
  Popover,
  Typography,
  Slide,
} from "@mui/material";

// Icon import
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import RefreshIcon from "@mui/icons-material/Refresh";

import { QueryClient, QueryClientProvider } from "react-query";
import { Helmet, HelmetProvider } from "react-helmet-async";
import GraphApp from "../../graph/App";
import { RootContainer, AppFooter } from "../Style/styled";

const queryClient = new QueryClient();

const isValidHexString = (input: string): boolean => {
  // Regex pattern to validate Ethereum addresses
  const hexStringPattern = /^(0x)?[0-9a-fA-F]{0,70}$/;
  return hexStringPattern.test(input);
};

const App: React.FC = () => {
  // Reload, FullScreen, Exit button State
  const [anchorEl1, setAnchorEl1] = React.useState<HTMLElement | null>(null);
  const [anchorEl2, setAnchorEl2] = React.useState<HTMLElement | null>(null);
  const [anchorEl3, setAnchorEl3] = React.useState<HTMLElement | null>(null);

  // Start, Stop button button State
  const [anchorEl4, setAnchorEl4] = React.useState<HTMLElement | null>(null);
  const [anchorEl5, setAnchorEl5] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement>,
    popoverId: number
  ) => {
    switch (popoverId) {
      case 1:
        setAnchorEl1(event.currentTarget);
        break;
      case 2:
        setAnchorEl2(event.currentTarget);
        break;
      case 3:
        setAnchorEl3(event.currentTarget);
        break;
      case 4:
        setAnchorEl4(event.currentTarget);
        break;
      case 5:
        setAnchorEl5(event.currentTarget);
        break;
      default:
        break;
    }
  };

  const handlePopoverClose = (popoverId: number) => {
    switch (popoverId) {
      case 1:
        setAnchorEl1(null);
        break;
      case 2:
        setAnchorEl2(null);
        break;
      case 3:
        setAnchorEl3(null);
        break;
      case 4:
        setAnchorEl4(null);
        break;
      case 5:
        setAnchorEl5(null);
        break;
      default:
        break;
    }
  };

  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);
  const open3 = Boolean(anchorEl3);
  const open4 = Boolean(anchorEl4);
  const open5 = Boolean(anchorEl5);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sliderValue = searchParams.get("slider") ?? 1000;
  const querySliderValue = searchParams.get("query") ?? 15;
  const checkboxParam = searchParams.get("checkbox");
  const checkboxValue = checkboxParam ? checkboxParam === "true" : false;
  const detailedOutput = {
    v3SwapOut: searchParams.get("v3SwapOut") === "true",
    v3SwapIn: searchParams.get("v3SwapIn") === "true",
    transfer: searchParams.get("transfer") === "true",
    permit2Permit: searchParams.get("permit2Permit") === "true",
    payPortion: searchParams.get("payPortion") === "true",
    sweep: searchParams.get("sweep") === "true",
    v2SwapOut: searchParams.get("v2SwapOut") === "true",
    v2SwapIn: searchParams.get("v2SwapIn") === "true",
    unwrapWeth: searchParams.get("unwrapWeth") === "true",
    wrapEth: searchParams.get("wrapEth") === "true",
  };

  const [txSubscribe, setTxSubscribe] = useState(false);
  const [txpoolSubscribe, setTxpoolSubscribe] = useState(
    !Boolean(checkboxValue) ?? false
  ); // State to manage subscription
  const [trackValue, setTrackValue] = useState("");
  const [error, setError] = useState("");
  const [count, setCount] = useState(0);

  const handleClick = () => setCount((current) => current + 1);

  const handleInputChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    const inputString = event.target.value.toString().toLowerCase().trim();
    // Check if the input value is a valid Ethereum address
    if (!isValidHexString(inputString)) {
      setError("Please enter a valid hex value.");
    } else {
      setError("");
      setTrackValue(inputString);
      handleClick();
    }
  };

  const handleStartButtonClick = () => {
    setTxSubscribe(!true);
    Boolean(checkboxValue)
      ? setTxpoolSubscribe(!true)
      : setTxpoolSubscribe(!false);
  };

  const handleStopButtonClick = () => {
    setTxSubscribe(true);
    setTxpoolSubscribe(true);
  };

  const handleFullScreen = () => {
    const element = document.documentElement;
    if (document.fullscreenElement) {
      // If already in full screen, exit full screen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    } else {
      // If not in full screen, request full screen
      if (element.requestFullscreen) {
        element.requestFullscreen();
      }
    }
  };

  const handleRefresh = () => window.location.reload();

  // footer conceal function
  const [showFooter, setShowFooter] = useState(false);
  const triggerThreshold = 200; // Distance from the bottom to trigger the footer

  const handleMouseMovement = (e: { clientY: number }) => {
    const isNearBottom = window.innerHeight - e.clientY < triggerThreshold;
    setShowFooter(isNearBottom);
  };

  return (
    <HelmetProvider>
      <Helmet>
        <link href="/fontawesome/css/fontawesome.min.css" rel="stylesheet" />
        <link href="/fontawesome/css/brands.min.css" rel="stylesheet" />
        <link href="/fontawesome/css/solid.min.css" rel="stylesheet" />
      </Helmet>
      <RootContainer disableGutters maxWidth={false}>
        <Grid
          container
          spacing={1}
          alignItems="center"
          style={{ textAlign: "left" }}
          onMouseMove={handleMouseMovement}
        >
          <QueryClientProvider client={queryClient}>
            <GraphApp
              nodeNumber={sliderValue}
              queryNumber={querySliderValue}
              txSubscribe={txSubscribe}
              txpoolSubscribe={txpoolSubscribe}
              trackValue={trackValue}
              count={count}
              detailedOutput={detailedOutput}
            />
          </QueryClientProvider>
        </Grid>
        <Slide direction="up" in={showFooter} mountOnEnter unmountOnExit>
          <AppFooter style={{ margin: "0 0" }}>
            <Grid container spacing={2} alignItems="center">
              <Grid
                item
                xs={11}
                sm={4}
                md={6}
                alignItems="center"
                style={{ margin: "0 0" }}
              >
                <FormControl fullWidth sx={{ m: 1 }}>
                  <TextField
                    id="outlined-search"
                    label="Track BlockNumber, TransactionHash, Address"
                    type="search"
                    value={trackValue} // Set the value of the input to the state value
                    onChange={handleInputChange}
                    error={!!error}
                    helperText={error}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={6}
                sm={4}
                md={3}
                alignItems="center"
                style={{ margin: "0 0" }}
              >
                <ButtonGroup
                  disableElevation
                  variant="contained"
                  aria-label="Disabled elevation buttons"
                  style={{ height: "300" }}
                  sx={{
                    height: "60px", // Set your desired height here
                    // Other styles
                  }}
                >
                  <Button
                    id="mouse-over-popover4"
                    color="warning"
                    onClick={handleStopButtonClick}
                    aria-haspopup="true"
                    onMouseEnter={(e) => handlePopoverOpen(e, 4)}
                    onMouseLeave={() => handlePopoverClose(4)}
                  >
                    <StopIcon />
                    Stop
                    <Popover
                      id="mouse-over-popover4"
                      sx={{
                        pointerEvents: "none",
                      }}
                      open={open4}
                      anchorEl={anchorEl4}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      onClose={() => handlePopoverClose(4)}
                      disableRestoreFocus
                    >
                      <Typography sx={{ m: 1 }}>
                        Stop Downloading Graph Data
                      </Typography>
                    </Popover>
                  </Button>
                  <Button
                    id="mouse-over-popover5"
                    color="info"
                    onClick={handleStartButtonClick}
                    aria-haspopup="true"
                    onMouseEnter={(e) => handlePopoverOpen(e, 5)}
                    onMouseLeave={() => handlePopoverClose(5)}
                  >
                    <PlayArrowIcon />
                    Start
                    <Popover
                      id="mouse-over-popover5"
                      sx={{
                        pointerEvents: "none",
                      }}
                      open={open5}
                      anchorEl={anchorEl5}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      onClose={() => handlePopoverClose(5)}
                      disableRestoreFocus
                    >
                      <Typography sx={{ m: 1 }}>
                        Resume Downloading Graph Data
                      </Typography>
                    </Popover>
                  </Button>
                </ButtonGroup>
              </Grid>
              <Grid
                item
                xs={6}
                sm={4}
                md={3}
                alignItems="center"
                style={{ margin: "0 0" }}
              >
                <ButtonGroup
                  disableElevation
                  variant="contained"
                  aria-label="Disabled elevation buttons"
                  style={{ height: "300" }}
                  sx={{
                    height: "60px", // Set your desired height here
                    // Other styles
                  }}
                >
                  <IconButton
                    id="mouse-over-popover1"
                    onClick={handleRefresh}
                    aria-label="fingerprint"
                    color="secondary"
                    aria-haspopup="true"
                    onMouseEnter={(e) => handlePopoverOpen(e, 1)}
                    onMouseLeave={() => handlePopoverClose(1)}
                  >
                    <Popover
                      id="mouse-over-popover"
                      sx={{
                        pointerEvents: "none",
                      }}
                      open={open1}
                      anchorEl={anchorEl1}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      onClose={() => handlePopoverClose(2)}
                      disableRestoreFocus
                    >
                      <Typography sx={{ m: 1 }}>Reload</Typography>
                    </Popover>
                    <RefreshIcon />
                  </IconButton>
                  <IconButton
                    id="mouse-over-popover2"
                    onClick={handleFullScreen}
                    aria-label="fingerprint"
                    color="success"
                    aria-haspopup="true"
                    onMouseEnter={(e) => handlePopoverOpen(e, 2)}
                    onMouseLeave={() => handlePopoverClose(2)}
                  >
                    <Popover
                      id="mouse-over-popover2"
                      sx={{
                        pointerEvents: "none",
                      }}
                      open={open2}
                      anchorEl={anchorEl2}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      onClose={() => handlePopoverClose(2)}
                      disableRestoreFocus
                    >
                      <Typography sx={{ m: 1 }}>Full Screen</Typography>
                    </Popover>
                    <FullscreenIcon />
                  </IconButton>
                  <IconButton
                    id="mouse-over-popover3"
                    href="/"
                    aria-label="fingerprint"
                    color="error"
                    aria-haspopup="true"
                    onMouseEnter={(e) => handlePopoverOpen(e, 3)}
                    onMouseLeave={() => handlePopoverClose(3)}
                  >
                    <Popover
                      id="mouse-over-popover3"
                      sx={{
                        pointerEvents: "none",
                      }}
                      open={open3}
                      anchorEl={anchorEl3}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      onClose={() => handlePopoverClose(3)}
                      disableRestoreFocus
                    >
                      <Typography sx={{ m: 1 }}>Exit App</Typography>
                    </Popover>
                    <ExitToAppIcon />
                  </IconButton>
                </ButtonGroup>
              </Grid>
            </Grid>
          </AppFooter>
        </Slide>
      </RootContainer>
    </HelmetProvider>
  );
};
export default App;
