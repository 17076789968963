import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

// Styled settings

export const RootContainer = styled(Container)(({ theme }) => ({
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  textAlign: "center",
  fontFamily: "Motiva Sans, sans-serif",
  margin: "0 auto",
  maxWidth: "false",
  width: "100%",
}));

export const LogoWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginBottom: theme.spacing(0),
}));

export const Logo = styled("img")(({ theme }) => ({
  maxWidth: "100%",
  height: "auto",
}));

export const ImageWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginBottom: theme.spacing(2),
}));

export const Image = styled("img")(({ theme }) => ({
  maxWidth: "40%",
  height: "auto",
}));

export const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  fontWeight: 800,
  color: theme.palette.primary.main,
}));

export const Subtitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  fontWeight: 700,
}));

export const Footer = styled("footer")(({ theme }) => ({
  marginTop: "auto",
  padding: theme.spacing(2),
  backgroundColor: "#f5f5f5",
  textAlign: "center",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  bottom: 0,
}));

export const AppFooter = styled("footer")(({ theme }) => ({
  marginTop: "auto",
  padding: theme.spacing(2),
  textAlign: "center",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  bottom: 0,
  position: "fixed",
  backgroundColor: "rgba(0, 0, 0, 0.1)",
  zIndex: 1000,
}));

export const Copyright = styled("div")(({ theme }) => ({
  display: "block",
  marginTop: theme.spacing(1),
}));

export const IconWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  gap: "64px", // Adjust the gap between icons
  justifyContent: "center",
  alignItems: "center",
}));

export const Icon = styled("div")(({ theme }) => ({
  margin: theme.spacing(0, 1),
}));

export const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  position: "fixed",
  top: 10,
  left: 10,
  padding: "10px",
  color: theme.palette.text.secondary,
  zIndex: 1000,
}));
