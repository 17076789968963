import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import Router from "./router";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const App = () => (
  <BrowserRouter>
    <Router />
  </BrowserRouter>
);
root.render(<App />);
