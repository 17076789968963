import React, { useState } from "react";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import {
  Slider,
  Checkbox,
  Button,
  FormControlLabel,
  Grid,
  Alert,
  Box,
} from "@mui/material";
import GitHubIcon from "@mui/icons-material/GitHub";
import X from "@mui/icons-material/X";
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import BubbleChartIcon from "@mui/icons-material/BubbleChart";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import SettingsIcon from "@mui/icons-material/Settings";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import {
  RootContainer,
  Logo,
  LogoWrapper,
  ImageWrapper,
  Image,
  Title,
  Subtitle,
  Footer,
  Copyright,
  IconWrapper,
  Icon,
} from "../Style/styled";

const lightTheme = createTheme({ palette: { mode: "light" } });

//  Initial Transaction Query Slider Unit Marks
const queryMarks = [
  {
    value: 1,
    label: "1 tx",
  },
  {
    value: 50,
    label: "50 tx",
  },
  {
    value: 100,
    label: "100 tx",
  },
];

// Maximum Node Number Slider Unit Marks
const marks = [
  {
    value: 100,
    label: "100 nodes",
  },
  {
    value: 1500,
    label: "1.5k nodes",
  },
  {
    value: 3000,
    label: "3k nodes",
  },
];

const valuetext = (value: number) => `${value} Nodes`;
const queryValuetext = (value: number) => `${value} tx`;

const Home = () => {
  const [checked, setChecked] = useState(true);

  const handleCheckboxChange = (event: {
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) => setChecked(event.target.checked);

  const [querySliderValue, setQuerySliderValue] = React.useState(15); // Initial value for the query slider
  const [sliderValue, setSliderValue] = React.useState(800); // Initial value for the Node slider

  const [queryShowWarning, setQueryShowWarning] = React.useState(false); // Show warning of Query number
  const [showWarning, setShowWarning] = React.useState(false); // Show warning of Node number

  // Node slider handling
  const handleChange = (event: Event, newValue: number | number[]) => {
    const updatedValue = Array.isArray(newValue) ? newValue[0] : newValue; // Handles both single and array values
    setSliderValue(updatedValue);
    // Check if the value exceeds a certain threshold
    updatedValue > 1000 ? setShowWarning(true) : setShowWarning(false);
  };

  const queryHandleChange = (event: Event, newValue: number | number[]) => {
    const updatedValue = Array.isArray(newValue) ? newValue[0] : newValue; // Handles both single and array values
    setQuerySliderValue(updatedValue);
    // Check if the value exceeds a certain threshold
    updatedValue > 20 ? setQueryShowWarning(true) : setQueryShowWarning(false);
  };

  interface CheckboxItem {
    label: string;
    id: string;
  }
  const checkboxes: CheckboxItem[] = [
    { label: "V3_SWAP_EXACT_OUT", id: "v3SwapOut" },
    { label: "V3_SWAP_EXACT_IN", id: "v3SwapIn" },
    { label: "TRANSFER", id: "transfer" },
    { label: "PERMIT2_PERMIT", id: "permit2Permit" },
    { label: "PAY_PORTION", id: "payPortion" },
    { label: "SWEEP", id: "sweep" },
    { label: "V2_SWAP_EXACT_OUT", id: "v2SwapOut" },
    { label: "V2_SWAP_EXACT_IN", id: "v2SwapIn" },
    { label: "UNWRAP_WETH", id: "unwrapWeth" },
    { label: "WRAP_ETH", id: "wrapEth" },
  ];
  const initialCheckboxes: Record<string, boolean> = {
    v3SwapOut: true,
    v3SwapIn: true,
    transfer: true,
    permit2Permit: true,
    payPortion: true,
    sweep: true,
    v2SwapOut: true,
    v2SwapIn: true,
    unwrapWeth: true,
    wrapEth: true,
  };

  const [checkboxState, setCheckboxState] =
    useState<Record<string, boolean>>(initialCheckboxes);

  const handleCheckboxChange2 =
    (id: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setCheckboxState({ ...checkboxState, [id]: event.target.checked });
    };


  return (
    <ThemeProvider theme={lightTheme}>
      <RootContainer disableGutters maxWidth={false}>
        <LogoWrapper>
          <Logo src="img/universalBase.svg" alt="Logo" />
        </LogoWrapper>
        <Title variant="h2">Universal Base</Title>
        <Subtitle variant="h5">Uniswap Universal Router</Subtitle>
        <Subtitle variant="h5">Real Time Graph Vizualization</Subtitle>
        <ImageWrapper>
          <Image src="img/screenshot.png" alt="Image" />
        </ImageWrapper>
        <Subtitle variant="h4">MAKE EVERY SWAP VISIBLE!</Subtitle>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} alignItems="center" style={{ margin: "0 20%" }}>
            <Typography variant="h6" gutterBottom align="left">
              <QueryStatsIcon />
              &nbsp;Initial Query
            </Typography>
            <Slider
              aria-label="Query Custom Marks"
              defaultValue={15}
              value={querySliderValue}
              onChange={queryHandleChange}
              aria-labelledby="QuerySlider"
              valueLabelDisplay="auto"
              step={1}
              min={1}
              max={100}
              marks={queryMarks}
              getAriaValueText={queryValuetext}
              color="secondary"
            />
            {queryShowWarning && (
              <Alert severity="warning" sx={{ marginTop: "10px" }}>
                Over 20 transactions might cause significant time to download
                and computation!
              </Alert>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} alignItems="center" style={{ margin: "0 20%" }}>
            <Typography variant="h6" gutterBottom align="left">
              <BubbleChartIcon />
              &nbsp;Maximum Node
            </Typography>
            <Slider
              aria-label="Node Custom Marks"
              value={sliderValue}
              onChange={handleChange}
              aria-labelledby="Nodeslider"
              valueLabelDisplay="auto"
              step={10}
              min={100}
              max={3000}
              marks={marks}
              getAriaValueText={valuetext}
              color="success"
            />
            {showWarning && (
              <Alert severity="warning" sx={{ marginTop: "10px" }}>
                Over 1000 Nodes use substantial computation! It might cause a
                browser to freeze.
              </Alert>
            )}
          </Grid>
          <Grid item xs={12} style={{ margin: "100 20%", height: 100 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleCheckboxChange}
                  color="warning"
                />
              }
              label="Transaction Pool Visualization"
              sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
            />
          </Grid>
          <Grid item xs={12} alignItems="center" style={{ margin: "0 10%" }}>
            <Typography variant="h6" gutterBottom align="left">
              <SettingsIcon />
              &nbsp;Detailed Output
            </Typography>
            <Box
              border={1}
              borderColor="primary.main"
              borderRadius={4}
              p={2}
              margin="0 auto"
            >
              <Grid container spacing={2}>
                {checkboxes.map((checkbox: CheckboxItem, index: number) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{ textAlign: "left" }}
                    key={checkbox.id}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="medium"
                          checked={checkboxState[checkbox.id]}
                          onChange={handleCheckboxChange2(checkbox.id)}
                        />
                      }
                      label={checkbox.label}

                      // You can handle checkbox state or other actions here
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} style={{ margin: "100 20%", height: 80 }}>
            <Link
              to={{
                pathname: "/app",
                search: `?slider=${sliderValue}&checkbox=${checked}&query=${querySliderValue}&${Object.keys(
                  checkboxState
                )
                  .filter((key) => checkboxState[key])
                  .map((key) => `${key}=true`)
                  .join("&")}`,
              }}
              style={{ textDecoration: "none" }}
            >
              <Button variant="contained" size="large" color="error">
                Launch App&nbsp;
                <RocketLaunchIcon />
              </Button>
            </Link>
          </Grid>
        </Grid>
        <Footer>
          <IconWrapper>
            <Link
              to="https://github.com/HiroyukiNaito/uniswap-universal-base"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Icon>
                <GitHubIcon />
              </Icon>
            </Link>
            <Link
              to="https://medium.com/@hiroyukinaito.eth/web3-app-universal-base-explained-9e4e84b94e9d"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Icon>
                <AutoStoriesIcon />
              </Icon>
            </Link>
            <Link
              to="https://twitter.com/hnaito_eth"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Icon>
                <X />
              </Icon>
            </Link>
          </IconWrapper>
          <Copyright>
            &copy; {new Date().getFullYear()} Universal Base All rights
            reserved. Powered by&nbsp;
            <Link
              to="https://solidoak.tech/"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Solid Oak
            </Link>
            .
          </Copyright>
        </Footer>
      </RootContainer>
    </ThemeProvider>
  );
};
export default Home;
