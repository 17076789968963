// Getting txnData Objects
// export const txnData = (responseData) => ([...responseData.txnList]);
export const txnData = (responseData) => (
          (responseData.hasOwnProperty("txnList")) ? [...responseData.txnList]
        : (responseData.hasOwnProperty("txnBulk")) ? [...responseData.txnBulk]
        : (responseData.hasOwnProperty("l2txnBulk")) ? [...responseData.l2txnBulk]
        : (responseData.hasOwnProperty("txnPool")) ? [responseData.txnPool]
        : (responseData.hasOwnProperty("txn")) ? [responseData.txn]
        : (responseData.hasOwnProperty("l2txn")) ? [responseData.l2txn]
        : (responseData.hasOwnProperty("l2txnList")) ? [...responseData.l2txnList]
        : (() => {throw new Error('No such graphql query or subscription!')})() 
)

// Change constants 
const MSG_SENDER_CONSTANT = "0x0000000000000000000000000000000000000001";
const UNIVERSAL_ROUER_ADDRESS_CONSTANT  = "0x0000000000000000000000000000000000000002";

export const uniswapAddressChange = (data, address) =>
                             (address === MSG_SENDER_CONSTANT) ? data.from.toLowerCase()
                                                               : (address === UNIVERSAL_ROUER_ADDRESS_CONSTANT)
                                                               ? "0x3fC91A3afd70395Cd496C647d5a6CC9D4B2b7FAD".toLowerCase()
                                                               : address.toLowerCase()

/*** 
 *** NODE OBJECT PRODUCTION ***
***/
export const userNodeData = (responseData) => 
    txnData(responseData).map(data => ({"node_name": "user", "id": `from_${data.from.toLowerCase()}`, "group": `from_${data.from.toLowerCase()}`, "value": `${data.from.toLowerCase()}`}));

export const blockNodeData = (responseData) => 
    txnData(responseData).map(data => ({"node_name": "block", "id": `block_${data.blockNumber}`, "group": "block", "value": `${data.blockNumber}`, "meta": {
     baseFeePerGas: data.blockHeader.baseFeePerGas,
     difficulty: data.blockHeader.difficulty,
     extraData: data.blockHeader.extraData.toLowerCase(),
     gasLimit: data.blockHeader.gasLimit,
     gasUsed: data.blockHeader.gasUsed,
     hash: data.blockHeader.hash.toLowerCase(),
     miner: data.blockHeader.miner,
     nonce: data.blockHeader.nonce,
     number: data.blockHeader.number,
     parentHash: data.blockHeader.parentHash,
     timestamp: data.blockHeader.timestamp,
    }}));

export const txnNodeData = (responseData) => 
    txnData(responseData).map(data => ({"node_name": "transaction", "id": `hash_${data.hash}`, "group": `hash_${data.hash}` , "value": `${data.hash}` , "meta": {
    blockNumber: data.blockNumber,
    blockHash: data.blockHash,
    type: data.type,
    to: data.to.toLowerCase(),
    from: data.from.toLowerCase(),
    nonce: data.nonce,
    gasLimit: data.gasLimit,
    gasPrice: data.gasPrice,
    maxPriorityFeePerGas: data.maxPriorityFeePerGas,
    maxFeePerGas: data.maxFeePerGas,
    value: data.value
    }}));

export const commandsNodeData = (responseData) => {
       const commandArray = txnData(responseData).map(data => 
         (data.decodedData.contents.map((commands, index) => 
         ({"node_name": `command_${index+1}`, "id": `command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": `${commands.value}`})
         )));
       return commandArray.flat(1)
      };


export const deadlineNodeData = (responseData) => 
      txnData(responseData).map(data => ({"node_name": "deadline", "id": `deadline_${data.hash}`, "group": `hash_${data.hash}` , "value": `${data.decodedData.deadline}`}));


// respective command 
// WRAP_ETH Node
export const wrapEthNodeData = (responseData) => {
          const commandArray = txnData(responseData).map(data => 
                  data.decodedData.contents.map((commands, index) => 
                       commands.value==='WRAP_ETH' ? ([{"node_name": "recipient", "id": `${uniswapAddressChange(data, commands.decodedInput[0])}`, "group": `${uniswapAddressChange(data, commands.decodedInput[0])}`, "value": `${uniswapAddressChange(data, commands.decodedInput[0])}`},
                                                          {"node_name": "amount", "id": `amount_command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": `${commands.decodedInput[1]}`}]) 
                                                      : null                                     
                  ).filter((commands) => commands !== null)
          )
          return commandArray.flat(2)
          };
// UNWRAP_WETH Node
export const unwrapWethNodeData = (responseData) => {
             const commandArray = txnData(responseData).map(data => 
                  data.decodedData.contents.map((commands, index) => 
                       commands.value==='UNWRAP_WETH' ? ([{"node_name": "recipient", "id": `${uniswapAddressChange(data,commands.decodedInput[0])}`, "group": `${uniswapAddressChange(data,commands.decodedInput[0])}`, "value": `${uniswapAddressChange(data,commands.decodedInput[0])}`},
                                                             {"node_name": "amount", "id": `amount_command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": `${commands.decodedInput[1]}`}]) 
                                                         : null                                     
                        ).filter((commands) => commands !== null)
                  )
       return commandArray.flat(2)
 };
 
 // V2_SWAP_EXACT_IN Node

const v2PathNodeData = (data, pathArray) => pathArray.map((path)=> ( {"node_name": "path", "id": `${uniswapAddressChange(data,path)}`, "group": `${uniswapAddressChange(data,path)}`, "value": `${uniswapAddressChange(data,path)}`}));

export const v2SwapInNodeData = (responseData) => {
  const commandArray = txnData(responseData).map(data => 
       data.decodedData.contents.map((commands, index) => 
            commands.value==='V2_SWAP_EXACT_IN' ? ([{"node_name": "recipient", "id": `${uniswapAddressChange(data,commands.decodedInput[0])}`, "group": `${uniswapAddressChange(data,commands.decodedInput[0])}`, "value": `${uniswapAddressChange(data,commands.decodedInput[0])}`},
                                                       {"node_name": "amount", "id": `amount_command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": `${commands.decodedInput[1]}`},
                                                       {"node_name": "min_amount", "id": `min_amount_command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": `${commands.decodedInput[2]}`},
                                                        v2PathNodeData(data, commands.decodedInput[3]),
                                                       {"node_name": "is_msg_sender", "id": `is_msg_sender_command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": `${commands.decodedInput[4]}`}]) 
                                                : null                                     
             ).filter((commands) => commands !== null)
       )
return commandArray.flat(3)
};

 // V2_SWAP_EXACT_OUT Node
 export const v2SwapOutNodeData = (responseData) => {
     const commandArray = txnData(responseData).map(data => 
          data.decodedData.contents.map((commands, index) => 
               commands.value==='V2_SWAP_EXACT_OUT' ? ([{"node_name": "recipient", "id": `${uniswapAddressChange(data,commands.decodedInput[0])}`, "group": `${uniswapAddressChange(data,commands.decodedInput[0])}`, "value": `${uniswapAddressChange(data,commands.decodedInput[0])}`},
                                                          {"node_name": "amount", "id": `amount_command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": `${commands.decodedInput[1]}`},
                                                          {"node_name": "max_amount", "id": `max_amount_command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": `${commands.decodedInput[2]}`},
                                                          v2PathNodeData(data, commands.decodedInput[3]),
                                                          {"node_name": "is_msg_sender", "id": `is_msg_sender_command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": `${commands.decodedInput[4]}`}]) 
                                                    : null                                     
                ).filter((commands) => commands !== null)
          )
   return commandArray.flat(3)
};

// SWEEP Node
export const sweepNodeData = (responseData) => {
     const commandArray = txnData(responseData).map(data => 
          data.decodedData.contents.map((commands, index) => 
               commands.value==='SWEEP' ? ([{"node_name": "token", "id": `${uniswapAddressChange(data,commands.decodedInput[0])}`, "group": `${uniswapAddressChange(data,commands.decodedInput[0])}`, "value": `${uniswapAddressChange(data,commands.decodedInput[0])}`},
                                            {"node_name": "recipient", "id": `${uniswapAddressChange(data,commands.decodedInput[1])}`, "group": `${uniswapAddressChange(data,commands.decodedInput[1])}`, "value": `${uniswapAddressChange(data,commands.decodedInput[1])}`},
                                            {"node_name": "minimum_required_token", "id": `minimum_required_token_command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": `${commands.decodedInput[2]}`}]) 
                                        : null                                     
                ).filter((commands) => commands !== null)
          )
return commandArray.flat(2)
};


// PAY_PORTION Node
export const payPortionNodeData = (responseData) => {
     const commandArray = txnData(responseData).map(data => 
          data.decodedData.contents.map((commands, index) => 
               commands.value==='PAY_PORTION' ? ([{"node_name": "token", "id": `${uniswapAddressChange(data, commands.decodedInput[0])}`, "group": `${uniswapAddressChange(data, commands.decodedInput[0])}`, "value": `${uniswapAddressChange(data, commands.decodedInput[0])}`},
                                                  {"node_name": "recipient", "id": `${uniswapAddressChange(data, commands.decodedInput[1])}`, "group": `${uniswapAddressChange(data, commands.decodedInput[1])}`, "value": `${uniswapAddressChange(data, commands.decodedInput[1])}`},
                                                  {"node_name": "bp_percentage", "id": `bp_percentage_command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": `${commands.decodedInput[2]}`}]) 
                                        : null                                     
                ).filter((commands) => commands !== null)
          )
return commandArray.flat(2)
};

// TRANSFER Node
export const transferNodeData = (responseData) => {
     const commandArray = txnData(responseData).map(data => 
          data.decodedData.contents.map((commands, index) => 
               commands.value==='TRANSFER' ? ([{"node_name": "token", "id": `${uniswapAddressChange(data,commands.decodedInput[0])}`, "group": `${uniswapAddressChange(data,commands.decodedInput[0])}`, "value": `${uniswapAddressChange(data,commands.decodedInput[0])}`},
                                            {"node_name": "recipient", "id": `${uniswapAddressChange(data,commands.decodedInput[1])}`, "group": `${uniswapAddressChange(data,commands.decodedInput[1])}`, "value": `${uniswapAddressChange(data,commands.decodedInput[1])}`},
                                            {"node_name": "amount", "id": `amount_command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": `${commands.decodedInput[2]}`}]) 
                                        : null                                     
                ).filter((commands) => commands !== null)
          )
return commandArray.flat(2)
};

 // PERMIT2_PERMIT Node
export const permit2PermitNodeData = (responseData) => {
   const commandArray = txnData(responseData).map(data => 
        data.decodedData.contents.map((commands, index) => 
             commands.value==='PERMIT2_PERMIT' ? ([{"node_name": "token", "id": `${uniswapAddressChange(data,commands.decodedInput[0][0][0])}`, "group": `${uniswapAddressChange(data,commands.decodedInput[0][0][0])}`, "value": `${uniswapAddressChange(data,commands.decodedInput[0][0][0])}`},
                                                   {"node_name": "amount", "id": `amount_command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": `${commands.decodedInput[0][0][1]}`},
                                                   {"node_name": "expiration", "id": `expiration_command_${index+1}_${data.hash}`,"group": `hash_${data.hash}`, "value": `${commands.decodedInput[0][0][2]}`},
                                                   {"node_name": "nonce", "id": `nonce_command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": `${commands.decodedInput[0][0][3]}`},
                                                   {"node_name": "spender", "id": `${uniswapAddressChange(data,commands.decodedInput[0][1])}`, "group": `${uniswapAddressChange(data,commands.decodedInput[0][1])}`, "value": `${uniswapAddressChange(data,commands.decodedInput[0][1])}`},
                                                   {"node_name": "sig_deadline", "id": `sig_deadline_command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": `${commands.decodedInput[0][2]}`},
                                                   {"node_name": "signature", "id": `signature_command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": `${commands.decodedInput[1]}`}]) 
                                                 : null                                     
              ).filter((commands) => commands !== null)
        )
 return commandArray.flat(2)
 };

// V3_SWAP_EXACT_IN Node
const v3PathNodeData = (pathArray, commandIndex, data) => pathArray.map((path, index)=> (index % 2 === 0) ? ({"node_name": "path", "id": `${uniswapAddressChange(data,path)}`, "group": `${uniswapAddressChange(data,path)}`, "value": `${uniswapAddressChange(data,path)}`})
                                                :  {"node_name": "fee", "id": `fee_command_${commandIndex+1}_${data.hash}_${index}`, "group": `hash_${data.hash}`, "value": `${uniswapAddressChange(data,path)}`});

export const v3SwapInNodeData = (responseData) => {
       const commandArray = txnData(responseData).map(data => 
                   data.decodedData.contents.map((commands, index) => 
                       commands.value==='V3_SWAP_EXACT_IN' ? ([{"node_name": "recipient", "id": `${uniswapAddressChange(data,commands.decodedInput[0])}`, "group": `${uniswapAddressChange(data,commands.decodedInput[0])}`, "value": `${uniswapAddressChange(data,commands.decodedInput[0])}`},
                                                               {"node_name": "amount", "id": `amount_command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": `${commands.decodedInput[1]}`},
                                                               {"node_name": "min_amount", "id": `min_amount_command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": `${commands.decodedInput[2]}`},
                                                               v3PathNodeData(commands.decodedInput[3],index,data),
                                                               {"node_name": "is_msg_sender", "id": `is_msg_sender_command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": `${commands.decodedInput[4]}`}]) 
                                                            : null                                     
     ).filter((commands) => commands !== null)
)
return commandArray.flat(3)
};

//  V3_SWAP_EXACT_OUT Node
export const v3SwapOutNodeData = (responseData) => {
       const commandArray = txnData(responseData).map(data => 
                   data.decodedData.contents.map((commands, index) => 
                       commands.value==='V3_SWAP_EXACT_OUT' ? ([{"node_name": "recipient", "id": `${uniswapAddressChange(data,commands.decodedInput[0])}`, "group": `${uniswapAddressChange(data,commands.decodedInput[0])}`, "value": `${uniswapAddressChange(data,commands.decodedInput[0])}`},
                                                               {"node_name": "amount", "id": `amount_command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": `${commands.decodedInput[1]}`},
                                                               {"node_name": "max_amount", "id": `max_amount_command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": `${commands.decodedInput[2]}`},
                                                               v3PathNodeData(commands.decodedInput[3],index,data),
                                                               {"node_name": "is_msg_sender", "id": `is_msg_sender_command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": `${commands.decodedInput[4]}`}]) 
                                                            : null                                     
     ).filter((commands) => commands !== null)
)
return commandArray.flat(3)
};


/*** 
 *** ARROW OBJECT PRODUCTION ***
***/
export const userToTxnArrowData = (responseData) => 
     txnData(responseData).map(data => ({"arrow_name": "user_to_transaction", "source": `from_${data.from.toLowerCase()}`, "target": `hash_${data.hash}`, "group": `hash_${data.hash}`, "value": "Transaction sent"} ));

export const blockToBlockArrowData = (responseData) => {
      const blockNode = [...blockNodeData(responseData)].filter(function({id}){return !this.has(id) && this.add(id);}, new Set());
      return (blockNode.length > 0)?(blockNode.sort((a, b) => a.value - b.value)
                                               .map((data, index, array) => index > 0 ? ({"arrow_name": "block_to_block", "source": `block_${array[index-1].value}`, "target": `block_${data.value}`, "group": "block", "value": "Next block"}) : null)
                                               .filter((arrow) => arrow !== null))
                                   :[]
} 

export const blockToTxnArrowData = (responseData) => 
     txnData(responseData).map(data => ({"arrow_name": "block_to_transaction", "source": `block_${data.blockNumber}`, "target": `hash_${data.hash}`, "group": `hash_${data.hash}`, "value": "Settled"}));

export const commandsArrowData = (responseData) => {
       const commandArray = txnData(responseData).map(data => 
            (data.decodedData.contents.map((commands, index) => 
                   (index===0) ? ({"arrow_name": `to_command_${index+1}`,  "source":  `hash_${data.hash}`, "target": `command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": `${commands.value} executed`})
                               : ({"arrow_name": `to_command_${index+1}`,  "source": `command_${index}_${data.hash}`, "target": `command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`,"value": `${commands.value} executed`})      
            )));
       return commandArray.flat(1)
       };


export const deadlineArrowData = (responseData) => 
       txnData(responseData).map(data => ({"arrow_name": "transaction_to_deadline", "source": `hash_${data.hash}`, "target": `deadline_${data.hash}`, "group": `hash_${data.hash}` , "value": "Transaction deadline"}));
 

// respective command 

//WRAP_ETH Arrow
export const wrapEthArrowData = (responseData) => {
  const commandArray = txnData(responseData).map(data => 
                    data.decodedData.contents.map((commands, index) => 
                         commands.value==='WRAP_ETH' ? ([{"arrow_name": "command_to_recipient", "source": `command_${index+1}_${data.hash}`, "target":`${uniswapAddressChange(data,commands.decodedInput[0])}`, "group": `hash_${data.hash}`, "value": "The recipient of the WETH"},
                                                       {"arrow_name": "command_to_amount", "source": `command_${index+1}_${data.hash}`, "target":`amount_command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": "The amount of ETH to wrap"}])                                 
                                                     : null
                          ).filter((commands) => commands !== null)
                     )
  return commandArray.flat(2)
 };

//UNWRAP_WETH Arrow
 export const unwrapWethArrowData = (responseData) => {
   const commandArray = txnData(responseData).map(data => 
                     data.decodedData.contents.map((commands, index) => 
                         commands.value==='UNWRAP_WETH' ? ([{"arrow_name": "command_to_recipient", "source": `command_${index+1}_${data.hash}`, "target":`${uniswapAddressChange(data,commands.decodedInput[0])}`, "group": `hash_${data.hash}`, "value": "The recipient of the ETH"},
                                                            {"arrow_name": "command_to_amount", "source": `command_${index+1}_${data.hash}`, "target":`amount_command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": "The minimum required ETH to receive from the unwrapping"}])                             
                                                        : null
                          ).filter((commands) => commands !== null)
                      )
  return commandArray.flat(2)
 };

// V2_SWAP_EXACT_IN Arrow
const v2PathArrowData = (pathArray, commandIndex, data) => 
           pathArray.map((path, index, array) => index===0 ? ({"arrow_name": "command_to_path", "source": `command_${commandIndex+1}_${data.hash}`, "target": `${uniswapAddressChange(data,path)}`, "group": `hash_${data.hash}`, "value": "Uniswap V2 Path"})
                                                           : ({"arrow_name": "path_to_path",  "source":  `${uniswapAddressChange(data,array[index-1])}`, "target": `${uniswapAddressChange(data,path)}`, "group": `hash_${data.hash}`, "value": "Uniswap V2 Path"}));

export const v2SwapInArrowData = (responseData) => {
  const commandArray = txnData(responseData).map(data => 
       data.decodedData.contents.map((commands, index) => 
            commands.value==='V2_SWAP_EXACT_IN' ? ([{"arrow_name": "command_to_recipient", "source": `command_${index+1}_${data.hash}`, "target":`${uniswapAddressChange(data,commands.decodedInput[0])}`, "group": `hash_${data.hash}`, "value": "The recipient of the output of the trade"},
                                                    {"arrow_name": "command_to_amount", "source": `command_${index+1}_${data.hash}`,"target": `amount_command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": "The amount of input tokens for the trade"},
                                                    {"arrow_name": "command_to_min_amount", "source": `command_${index+1}_${data.hash}`,"target": `min_amount_command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": "The minimum amount of output tokens the user wants"},
                                                    v2PathArrowData(commands.decodedInput[3], index, data),
                                                    {"arrow_name": "command_to_is_msg_sender","source": `command_${index+1}_${data.hash}`, "target": `is_msg_sender_command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": "Input tokens come from msg.sender or UniversalRouter"}]) 
                                                : null                                     
             ).filter((commands) => commands !== null)
       )
   return commandArray.flat(3)
};

// V2_SWAP_EXACT_OUT Arrow
export const v2SwapOutArrowData = (responseData) => {
     const commandArray = txnData(responseData).map(data => 
          data.decodedData.contents.map((commands, index) => 
               commands.value==='V2_SWAP_EXACT_OUT' ? ([{"arrow_name": "command_to_recipient", "source": `command_${index+1}_${data.hash}`, "target":`${uniswapAddressChange(data,commands.decodedInput[0])}`, "group": `hash_${data.hash}`, "value": "The recipient of the output of the trade"},
                                                        {"arrow_name": "command_to_amount", "source": `command_${index+1}_${data.hash}`,"target": `amount_command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": "The amount of output tokens to receive"},
                                                        {"arrow_name": "command_to_max_amount", "source": `command_${index+1}_${data.hash}`,"target": `max_amount_command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": "The maximum number of input tokens that should be spent"},
                                                        v2PathArrowData(commands.decodedInput[3], index, data),
                                                        {"arrow_name": "command_to_is_msg_sender","source": `command_${index+1}_${data.hash}`, "target": `is_msg_sender_command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": "Input tokens come from msg.sender or UniversalRouter"}]) 
                                                 : null                                     
                ).filter((commands) => commands !== null)
          )
   return commandArray.flat(3)
};

//SWEEP Arrow
export const sweepArrowData = (responseData) => {
     const commandArray = txnData(responseData).map(data => 
                       data.decodedData.contents.map((commands, index) => 
                           commands.value==='SWEEP' ? ([{"arrow_name": "command_to_token", "source": `command_${index+1}_${data.hash}`, "target":`${uniswapAddressChange(data,commands.decodedInput[0])}`, "group": `hash_${data.hash}`, "value": "The ERC20 token to sweep (or Constants.ETH for ETH)"},
                                                        {"arrow_name": "command_to_recipient", "source": `command_${index+1}_${data.hash}`, "target":`${uniswapAddressChange(data,commands.decodedInput[1])}`, "group": `hash_${data.hash}`, "value": "The recipient of the sweep"},
                                                        {"arrow_name": "command_to_minimum_required_token", "source": `command_${index+1}_${data.hash}`, "target":`minimum_required_token_command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": "The minimum required ETH to receive from the unwrapping"}])                             
                                                      : null
                            ).filter((commands) => commands !== null)
                        )
   return commandArray.flat(2)
};

//PAY_PORTION Arrow
export const payPortionArrowData = (responseData) => {
     const commandArray = txnData(responseData).map(data => 
                       data.decodedData.contents.map((commands, index) => 
                           commands.value==='PAY_PORTION' ? ([{"arrow_name": "command_to_token", "source": `command_${index+1}_${data.hash}`, "target":`${uniswapAddressChange(data,commands.decodedInput[0])}`, "group": `hash_${data.hash}`, "value": "The ERC20 token to transfer (or Constants.ETH for ETH)"},
                                                              {"arrow_name": "command_to_recipient", "source": `command_${index+1}_${data.hash}`, "target":`${uniswapAddressChange(data,commands.decodedInput[1])}`, "group": `hash_${data.hash}`, "value": "The recipient of the transfer"},
                                                              {"arrow_name": "command_to_bp_percentage", "source": `command_${index+1}_${data.hash}`, "target":`bp_percentage_command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": "In basis points, the percentage of the contract’s balance to transfer"}])                             
                                                      : null
                            ).filter((commands) => commands !== null)
                        )
    return commandArray.flat(2)
};

//PAY_PORTION Arrow
export const transferArrowData = (responseData) => {
     const commandArray = txnData(responseData).map(data => 
                       data.decodedData.contents.map((commands, index) => 
                           commands.value==='TRANSFER' ? ([{"arrow_name": "command_to_token", "source": `command_${index+1}_${data.hash}`, "target":`${uniswapAddressChange(data,commands.decodedInput[0])}`, "group": `hash_${data.hash}`, "value": "The ERC20 token to transfer (or Constants.ETH for ETH)"},
                                                              {"arrow_name": "command_to_recipient", "source": `command_${index+1}_${data.hash}`, "target":`${uniswapAddressChange(data,commands.decodedInput[1])}`, "group": `hash_${data.hash}`, "value": "The recipient of the transfer"},
                                                              {"arrow_name": "command_to_amount", "source": `command_${index+1}_${data.hash}`, "target":`amount_command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": "The amount to transfer"}])                             
                                                      : null
                            ).filter((commands) => commands !== null)
                        )
    return commandArray.flat(2)
};

 // PERMIT2_PERMIT Arrow
export const permit2PermitArrowData = (responseData) => {
     const commandArray = txnData(responseData).map(data => 
          data.decodedData.contents.map((commands, index) => 
               commands.value==='PERMIT2_PERMIT' ? ([{"arrow_name": "command_to_token", "source":  `command_${index+1}_${data.hash}`,"target": `${uniswapAddressChange(data,commands.decodedInput[0][0][0])}`, "group": `hash_${data.hash}`, "value": "ERC20 token address"},
                                                     {"arrow_name": "command_to_amount", "source": `command_${index+1}_${data.hash}`,"target": `amount_command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": "The maximum amount allowed to spend"},
                                                     {"arrow_name": "command_to_expiration","source": `command_${index+1}_${data.hash}`, "target": `expiration_command_${index+1}_${data.hash}`,"group": `hash_${data.hash}`, "value": "Timestamp at which a spender's token allowances become invalid"},
                                                     {"arrow_name": "command_to_nonce", "source": `command_${index+1}_${data.hash}`,"target": `nonce_command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": "nonce: An incrementing value indexed per owner,token,and spender for each signature"},
                                                     {"arrow_name": "commmand_to_spender", "source": `command_${index+1}_${data.hash}`,"target": `${uniswapAddressChange(data,commands.decodedInput[0][1])}`,  "group": `hash_${data.hash}`, "value": "Address permissioned on the allowed tokens"},
                                                     {"arrow_name": "command_to_sig_deadline", "source": `command_${index+1}_${data.hash}`,"target": `sig_deadline_command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": "Deadline on the permit signature"},
                                                     {"arrow_name": "command_to_signature","source": `command_${index+1}_${data.hash}`, "target": `signature_command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": "The signature to provide to Permit2"}]) 
                                                   : null                                     
                ).filter((commands) => commands !== null)
          )
   return commandArray.flat(2)
};


// V3_SWAP_EXACT_IN Arrow
const v3PathArrowData = (pathArray, commandIndex, data) => 
           pathArray.map((path, index, array) => index===0 ? ({"arrow_name": "command_to_path", "source": `command_${commandIndex+1}_${data.hash}`, "target": `${uniswapAddressChange(data,path)}`, "group": `hash_${data.hash}`, "value": "Uniswap V3 Path"})
                                                           : (index % 2 === 0) ? ({"arrow_name": "fee_to_path",  "source":  `fee_command_${commandIndex+1}_${data.hash}_${index-1}`, "target": `${uniswapAddressChange(data,path)}`, "group": `hash_${data.hash}`, "value": "Uniswap V3 Path"})
                                                                               : ({"arrow_name": "path_to_fee",  "source":  `${uniswapAddressChange(data,array[index-1])}`, "target": `fee_command_${commandIndex+1}_${data.hash}_${index}`, "group": `hash_${data.hash}`, "value": "Uniswap V3 Path"}));

export const v3SwapInArrowData = (responseData) => {
  const commandArray = txnData(responseData).map(data => 
       data.decodedData.contents.map((commands, index) => 
            commands.value==='V3_SWAP_EXACT_IN' ? ([{"arrow_name": "command_to_recipient", "source": `command_${index+1}_${data.hash}`, "target":`${uniswapAddressChange(data,commands.decodedInput[0])}`, "group": `hash_${data.hash}`, "value": "The recipient of the output of the trade"},
                                                    {"arrow_name": "command_to_amount", "source": `command_${index+1}_${data.hash}`,"target": `amount_command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": "The amount of input tokens for the trade"},
                                                    {"arrow_name": "command_to_min_amount", "source": `command_${index+1}_${data.hash}`,"target": `min_amount_command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": "The minimum amount of output tokens the user wants"},
                                                     v3PathArrowData(commands.decodedInput[3], index, data),
                                                    {"arrow_name": "command_to_is_msg_sender","source": `command_${index+1}_${data.hash}`, "target": `is_msg_sender_command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": "Input tokens come from msg.sender or UniversalRouter"}]) 
                                                : null                                     
             ).filter((commands) => commands !== null)
       )
return commandArray.flat(3)
};

// V3_SWAP_EXACT_OUT Arrow
export const v3SwapOutArrowData = (responseData) => {
     const commandArray = txnData(responseData).map(data => 
          data.decodedData.contents.map((commands, index) => 
               commands.value==='V3_SWAP_EXACT_OUT' ? ([{"arrow_name": "command_to_recipient", "source": `command_${index+1}_${data.hash}`, "target":`${uniswapAddressChange(data,commands.decodedInput[0])}`, "group": `hash_${data.hash}`, "value": "The recipient of the output of the trade"},
                                                       {"arrow_name": "command_to_amount", "source": `command_${index+1}_${data.hash}`,"target": `amount_command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": "The amount of output tokens to receive"},
                                                       {"arrow_name": "command_to_max_amount", "source": `command_${index+1}_${data.hash}`,"target": `max_amount_command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": "The maximum number of input tokens that should be spent"},
                                                        v3PathArrowData(commands.decodedInput[3], index, data),
                                                       {"arrow_name": "command_to_is_msg_sender","source": `command_${index+1}_${data.hash}`, "target": `is_msg_sender_command_${index+1}_${data.hash}`, "group": `hash_${data.hash}`, "value": "Input tokens come from msg.sender or UniversalRouter"}]) 
                                                   : null                                     
                ).filter((commands) => commands !== null)
          )
   return commandArray.flat(3)
};

// Generate block graph node data to block arrow data array
export const blockNodeToBlockArrowData = (nodeData) => {
     const blockNode = [...nodeData].filter((node) => node["node_name"] === "block").filter(function({id}){return !this.has(id) && this.add(id);}, new Set());
     return (blockNode.length > 1)? (blockNode.sort((a, b) => a.value - b.value)
                                              .map((data, index, array) => index > 0 ? ({"arrow_name": "block_to_block", "source": `block_${array[index-1].value}`, "target": `block_${data.value}`, "group": "block", "value": "Next block"}) : null)
                                              .filter((arrow) => arrow !== null))
                                  :[]
}

// Removing duplicate arrow objects
export const filterDuplicateArrow = (arrowObjects) => {
     return arrowObjects.reduce((unique, obj) => {
       return unique.some(item => item.source === obj.source && item.target === obj.target) ? unique : [...unique, obj];
     }, []);
   };
// Removing duplicate with **new** node objects
export const filterDuplicateNode = (nodeObjects) => {
     const uniqueNodes = nodeObjects.reduce((acc, obj) => {
          const existingObj = acc.find(item => item.id === obj.id);
          existingObj ? existingObj.meta = obj.meta : acc.push(obj);
          return acc;
     }, []);
     return uniqueNodes
}

// Getting txnData Objects
export const graphData = (responseData, detailedOutput) => ({nodes: filterDuplicateNode([
                                                     ...(v3SwapOutNodeData(responseData).filter(value => detailedOutput.v3SwapOut)),
                                                     ...v3SwapInNodeData(responseData).filter(value => detailedOutput.v3SwapIn),
                                                     ...permit2PermitNodeData(responseData).filter(value => detailedOutput.permit2Permit),
                                                     ...transferNodeData(responseData).filter(value => detailedOutput.transfer),
                                                     ...payPortionNodeData(responseData).filter(value => detailedOutput.payPortion),
                                                     ...sweepNodeData(responseData).filter(value => detailedOutput.sweep),
                                                     ...v2SwapOutNodeData(responseData).filter(value => detailedOutput.v2SwapOut),
                                                     ...v2SwapInNodeData(responseData).filter(value => detailedOutput.v2SwapIn),
                                                     ...unwrapWethNodeData(responseData).filter(value => detailedOutput.unwrapWeth),
                                                     ...wrapEthNodeData(responseData).filter(value => detailedOutput.wrapEth),
                                                     ...userNodeData(responseData),
                                                     ...deadlineNodeData(responseData),
                                                     ...blockNodeData(responseData),
                                                     ...txnNodeData(responseData),
                                                     ...commandsNodeData(responseData)
                                                    ]),
                                             links: filterDuplicateArrow([
                                                    ...v3SwapOutArrowData(responseData).filter(value => detailedOutput.v3SwapOut),
                                                     ...v3SwapInArrowData(responseData).filter(value => detailedOutput.v3SwapIn),
                                                     ...permit2PermitArrowData(responseData).filter(value => detailedOutput.permit2Permit),
                                                     ...transferArrowData(responseData).filter(value => detailedOutput.transfer),
                                                     ...payPortionArrowData(responseData).filter(value => detailedOutput.payPortion),
                                                     ...sweepArrowData(responseData).filter(value =>  detailedOutput.sweep),
                                                     ...v2SwapOutArrowData(responseData).filter(value => detailedOutput.v2SwapOut),
                                                     ...v2SwapInArrowData(responseData).filter(value => detailedOutput.v2SwapIn),
                                                     ...unwrapWethArrowData(responseData).filter(value => detailedOutput.unwrapWeth),
                                                     ...wrapEthArrowData(responseData).filter(value => detailedOutput.wrapEth),
                                                     ...blockToBlockArrowData(responseData),
                                                     ...deadlineArrowData(responseData),
                                                     ...userToTxnArrowData(responseData),
                                                     ...blockToTxnArrowData(responseData),
                                                    ...commandsArrowData(responseData)
                                                    ])
                                          });
export const poolGraphData = (responseData, detailedOutput) => ({nodes: filterDuplicateNode ([
                                                  ...(v3SwapOutNodeData(responseData).filter(value => detailedOutput.v3SwapOut)),
                                                  ...v3SwapInNodeData(responseData).filter(value => detailedOutput.v3SwapIn),
                                                  ...permit2PermitNodeData(responseData).filter(value => detailedOutput.permit2Permit),
                                                  ...transferNodeData(responseData).filter(value => detailedOutput.transfer),
                                                  ...payPortionNodeData(responseData).filter(value => detailedOutput.payPortion),
                                                  ...sweepNodeData(responseData).filter(value => detailedOutput.sweep),
                                                  ...v2SwapOutNodeData(responseData).filter(value => detailedOutput.v2SwapOut),
                                                  ...v2SwapInNodeData(responseData).filter(value => detailedOutput.v2SwapIn),
                                                  ...unwrapWethNodeData(responseData).filter(value => detailedOutput.unwrapWeth),
                                                  ...wrapEthNodeData(responseData).filter(value => detailedOutput.wrapEth),
                                                  ...deadlineNodeData(responseData),
                                                  ...userNodeData(responseData),
                                                  ...txnNodeData(responseData),
                                                  ...commandsNodeData(responseData)
                                                 ]),
                                          links: filterDuplicateArrow([
                                                  ...v3SwapOutArrowData(responseData).filter(value => detailedOutput.v3SwapOut),
                                                  ...v3SwapInArrowData(responseData).filter(value => detailedOutput.v3SwapIn),
                                                  ...permit2PermitArrowData(responseData).filter(value => detailedOutput.permit2Permit),
                                                  ...transferArrowData(responseData).filter(value => detailedOutput.transfer),
                                                  ...payPortionArrowData(responseData).filter(value => detailedOutput.payPortion),
                                                  ...sweepArrowData(responseData).filter(value =>  detailedOutput.sweep),
                                                  ...v2SwapOutArrowData(responseData).filter(value => detailedOutput.v2SwapOut),
                                                  ...v2SwapInArrowData(responseData).filter(value => detailedOutput.v2SwapIn),
                                                  ...unwrapWethArrowData(responseData).filter(value => detailedOutput.unwrapWeth),
                                                  ...wrapEthArrowData(responseData).filter(value => detailedOutput.wrapEth),
                                                  ...deadlineArrowData(responseData),
                                                  ...userToTxnArrowData(responseData),
                                                  ...commandsArrowData(responseData)
                                          ])});


                                       