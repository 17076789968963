import { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import App from "../pages/App";

const Router = () => {
  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/app" element={<App />} />
      </Routes>
    </Suspense>
  );
};
export default Router;
